import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userData: {},
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: () => initialState,
    updateUserData: (state, action) => {
      state.userData = {
        ...state.userData,
        ...action.payload.user,
      };
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.userData = {};
      state.token = '';
      state.loginStatus = '';
      state.error = {};
    },
  },
});

export const { clearState, updateUserData, logout } = authSlice.actions;
export default authSlice.reducer;
