// ReusableGrid.js
import React from 'react';
import Grid from '@mui/material/Grid';

function Col({ children, ...restProps }) {
  return (
    <Grid item {...restProps}>
      {children}
    </Grid>
  );
}

export default Col;
