import { Typography } from '@mui/material';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../../../assets/styles/signUp.css';
import Box from '../../../components/box';
import ROUTES from '../../../utils/constant/routePaths';
import SignUpForm from '../components/SignUpForm';
import { doRegister } from '../services/authAPI';
import { enqueueSnackbar } from 'notistack';

function SignUpPage() {
  const navigate = useNavigate();

  const onSignUp = (signUpData) => {
    doRegister(signUpData).then((res) => {
      if (res.status === 201) {
        navigate(ROUTES.USER.VERIFY_EMAIL, { state: signUpData.email });
        return;
      }
      enqueueSnackbar(res.message, { variant: 'error' });
    });
  };

  return (
    <React.Fragment>
      <Box className="text-center mb-2">
        <Typography variant="h4">Sign Up</Typography>
        <Typography variant="h6">
          {`Already have an account?`}
          <Link color="primary" to={ROUTES.USER.LOGIN}>
            <b> Sign In </b>
          </Link>
        </Typography>
      </Box>
      <SignUpForm onSignUp={onSignUp} />
    </React.Fragment>
  );
}

export default SignUpPage;
