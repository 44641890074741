import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../components/core/button';
import { availablePromoCodes } from '../services/dashboardApi';
import PromoCodeInfo from './PromoCodeInfo';
import PurchaseCode from './PurchaseCode';

function OfferInfoPopup() {
  const userInfo = useSelector((state) => state.auth.userData);

  const advertisementId = localStorage.getItem('advertisementId');
  const consumerId = localStorage.getItem('consumerId');
  const type = localStorage.getItem('type');

  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [promoCodeInfo, setPromoCodeInfo] = useState(null);
  const [showPromoCodeInfoPopup, setShowPromoCodeInfoPopup] = useState(false);
  const [promoCodes, setPromoCodes] = useState([]);

  const handleClose = () => {
    setShowInfoPopup(false);
  };

  useEffect(() => {
    if (type && advertisementId && consumerId && consumerId === userInfo?._id) {
      availablePromoCodes(advertisementId, type).then((res) => {
        const { statusCode, data } = res;
        if (statusCode === 200) {
          setPromoCodeInfo(data);
          setShowInfoPopup(true);
          localStorage.removeItem('advertisementId');
          localStorage.removeItem('consumerId');
        }
      });
    }
  }, [advertisementId, consumerId, type]);

  const handleSuccess = (promoCodes) => {
    setPromoCodes(promoCodes);
    setShowInfoPopup(false);
    setShowPromoCodeInfoPopup(true);
  };

  return (
    <>
      <Dialog open={showInfoPopup}>
        <DialogTitle id="responsive-dialog-title">
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Typography variant="h6" color="#4F8D7D">
                {promoCodeInfo?.availableCodeCount > 0
                  ? 'Purchase Promo Code'
                  : '!! IMPORTANT MESSAGE !!'}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} textAlign="right">
              <Button variant="text" onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>

        {promoCodeInfo?.availableCodeCount === 0 ? (
          <DialogContent>
            <Grid container spacing={2} mb={2}>
              <Grid item xs={12} sm={12}>
                <Typography>
                  We apologize for any inconvenience, but unfortunately all
                  promo codes have already been claimed. Thank you for your
                  understanding. Please remember to regularly check your email
                  as we consistently send out offers.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        ) : (
          <PurchaseCode
            type={type}
            promoCodeInfo={promoCodeInfo}
            handleSuccess={handleSuccess}
            updateAvailableCodeCount={() => {
              setPromoCodeInfo((prev) => ({ ...prev, availableCodeCount: 0 }));
            }}
          />
        )}
      </Dialog>
      <PromoCodeInfo
        promoCodes={promoCodes}
        showInfoPopup={showPromoCodeInfoPopup}
        handleClose={() => {
          setShowPromoCodeInfoPopup(false);
        }}
      />
    </>
  );
}

export default OfferInfoPopup;
