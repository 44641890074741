import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { loadStripe } from '@stripe/stripe-js';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import LoadingButton from '../../../components/core/button/LoadingButton';
import TextInputControl from '../../../components/core/input/TextInputControl';
import CheckoutForm from '../../payment/components/CheckoutForm';
import {
  confirmPayment,
  createPaymentIntent,
} from '../../payment/services/paymentApi';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function SingleDayAdvertisement({
  type,
  categories,
  open,
  handleClose,
  paymentMethods,
  setPaymentMethods,
  deleteCard,
  addAdvertisement,
}) {
  const userInfo = useSelector((state) => state.auth.userData);

  const [category, setCategory] = useState('');
  const [advertisementDate, setAdvertisementDate] = useState(dayjs());
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [submitPaymentLoading, setSubmitPaymentLoading] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        offerName: yup
          .string()
          .required('Offer name is required')
          .max(100, 'Offer name must be maximum 100 characters long'),

        originalPrice: yup
          .number()
          .typeError('Valid original price is required')
          .required('Valid original price is required')
          .min(1, 'Original price must be grater than 0.'),
        discountPrice: yup
          .number()
          .typeError('Valid discount price is required')
          .required('Valid discount price is required')
          .min(1, 'Discount price must be grater than 0.')
          .test(
            'discount-match',
            'The discounted price should be at least $4 lower than the original price.',
            function (value) {
              return this.parent.originalPrice - value >= 4;
            }
          ),
        totalQuantity: yup
          .number()
          .typeError('Valid quantity is required')
          .required('Valid quantity is required')
          .min(3, 'Quantity must be 3 or higher.'),
        amountDue: yup
          .number()
          .min(1, 'Amount due price must be grater than 0.'),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offerName: '',
      processingFee: 0.25,
      originalPrice: '',
      discountPrice: '',
      totalQuantity: '',
      amountDue: '',
    },
  });

  const originalPrice = watch('originalPrice');
  const discountPrice = watch('discountPrice');
  const totalQuantity = watch('totalQuantity');

  useEffect(() => {
    if (
      originalPrice > 0 &&
      discountPrice > 0 &&
      +originalPrice > +discountPrice
    ) {
      clearErrors('discountPrice');
    }

    if (originalPrice && discountPrice && totalQuantity) {
      const processingFee = getValues('processingFee');
      const amountDue =
        processingFee + (originalPrice - discountPrice) * totalQuantity * 0.08;
      setValue('amountDue', amountDue.toFixed(2));
    }
  }, [originalPrice, discountPrice, totalQuantity]);

  const resetAllFields = () => {
    reset();
    setPaymentIntent(null);
    setAdvertisementDate(dayjs());
    setCategory('');
    setSelectedPaymentMethod('');
  };

  const addNewCard = () => {
    setAddCardLoading(true);
    const amountDue = getValues('amountDue');
    stripePromise
      .then(() => {
        const amount = amountDue * 100;
        if (paymentIntent?.amount && amount === paymentIntent.amount) {
          setAddCardLoading(false);
          setShowCardPopup(true);
        } else {
          createPaymentIntent({ amount, customerId: userInfo.customerId }).then(
            (res) => {
              if (res?.data) {
                setIsStripeLoaded(true);
                setPaymentIntent({ ...res.data });
                setShowCardPopup(true);
              } else {
                enqueueSnackbar(res?.message, { variant: 'error' });
              }
              setAddCardLoading(false);
            }
          );
        }
      })
      .catch((error) => {
        console.log('🚀 ~ addNewCard ~ error:', error);
      });
  };

  const addOneDayAdvertisement = (data, event) => {
    event.preventDefault();
    setSubmitPaymentLoading(true);
    confirmPayment({
      amount: data.amountDue * 100,
      paymentMethodId: selectedPaymentMethod,
      customerId: userInfo.customerId,
      id: paymentIntent?.id,
    })
      .then(async (res) => {
        const { offerName, amountDue, processingFee } = data;
        const { provider: { _id: providerId } = {} } = userInfo;

        const paymentIntentId = res.data.paymentIntent.id;
        const reqData = {
          type: 'SINGLE_DAY',
          name: offerName,
          durationFrom: advertisementDate
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss.SSS'),
          durationTo: advertisementDate
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss.SSS'),
          advertisementCost: amountDue - processingFee,
          processingFee,
          quantity: totalQuantity,
          currency: 'USD',
          paymentId: paymentIntentId,
          originalPrice,
          discountedPrice: discountPrice,
          providerEmail: userInfo.email,
        };

        if (type === 'restaurant') {
          reqData.cuisineKey = category;
        } else {
          reqData.serviceKey = category;
        }

        await addAdvertisement(providerId, reqData, true).then(() => {
          setSubmitPaymentLoading(false);
          if (res) resetAllFields();
        });
      })
      .catch((error) => {
        console.log('🚀 ~ addOneDayAdvertisement ~ error:', error);
        enqueueSnackbar(error?.message ?? 'Payment failed!', {
          variant: 'error',
        });
        setSubmitPaymentLoading(false);
      });
  };

  return (
    <Grid item xs={12} sm={10} marginTop={5}>
      <CheckoutForm
        stripe={stripePromise}
        open={showCardPopup}
        isStripeLoaded={isStripeLoaded}
        clientSecret={paymentIntent?.clientSecret}
        handleClose={(event, paymentMethod) => {
          if (paymentMethod && typeof paymentMethod === 'object') {
            setPaymentMethods([...paymentMethods, paymentMethod]);
            setSelectedPaymentMethod(paymentMethod.id);
          }
          setShowCardPopup(false);
        }}
      />
      <Dialog
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          component: 'form',
          onSubmit: handleSubmit(addOneDayAdvertisement),
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container>
            <Grid item xs={12} sm={10}>
              <Typography variant="h4" color="#4F8D7D">
                One Day Advertisement
              </Typography>
            </Grid>
            <Grid item xs={12} sm={2} textAlign="right">
              <Button variant="text" onClick={handleClose}>
                <CloseIcon />
              </Button>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} marginTop={0.1}>
            <Grid item xs={12} sm={12}>
              <TextInputControl
                control={control}
                error={Boolean(errors.offerName)}
                id="offerName"
                label="Offer Name"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth={true}>
                <InputLabel id="category-select-label">
                  Category Name
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-simple-select"
                  value={category}
                  label="Category Name"
                  onChange={(event) => {
                    setCategory(event.target.value);
                  }}
                >
                  {categories?.map(({ key, name }) => (
                    <MenuItem key={key} value={key}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  format="MM/DD/YYYY"
                  label="Date"
                  sx={{ width: '100%' }}
                  value={advertisementDate}
                  onChange={(value) => {
                    setAdvertisementDate(value);
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
          <Grid container spacing={2} marginTop={0.1}>
            <Grid item xs={12} sm={6}>
              <TextInputControl
                id="originalPrice"
                control={control}
                label={
                  type === 'restaurant'
                    ? 'Original Price Of One Meal'
                    : 'Original Price Of The Service'
                }
                rules={{ required: true }}
                fullWidth
                error={Boolean(errors.originalPrice)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextInputControl
                id="discountPrice"
                control={control}
                label={
                  type === 'restaurant'
                    ? 'Discounted Price Of One Meal'
                    : 'Discounted Price Of The Service'
                }
                rules={{ required: true }}
                fullWidth
                error={Boolean(errors.discountPrice)}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInputControl
                id="totalQuantity"
                control={control}
                label="Total Quantity"
                rules={{ required: true }}
                fullWidth
                error={Boolean(errors.totalQuantity)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextInputControl
                control={control}
                disabled
                id="processingFee"
                label="Processing Fees"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextInputControl
                disabled
                id="amountDue"
                control={control}
                label="Amount Due"
                rules={{ required: true }}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <AttachMoneyIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} marginTop={2} className="testing">
            <Box
              border={1}
              padding={2}
              borderColor="rgba(0, 0, 0, 0.23)"
              borderRadius={1}
            >
              <FormControl fullWidth sx={{ mb: 1 }}>
                <FormLabel sx={{ mb: 1 }}>Choose a Payment Method</FormLabel>
                <RadioGroup
                  aria-labelledby="payment-method"
                  name="payment-methods-group"
                >
                  {paymentMethods?.map(
                    ({
                      id,
                      brand,
                      holderName,
                      expMonth,
                      expYear,
                      cardNumber,
                    }) => (
                      <Grid container key={id}>
                        <Grid item xs={11}>
                          <FormControlLabel
                            value={id}
                            control={
                              <Radio
                                checked={selectedPaymentMethod === id}
                                onChange={(event) => {
                                  setSelectedPaymentMethod(event.target.value);
                                }}
                              />
                            }
                            label={brand}
                            sx={{
                              textTransform: 'capitalize',
                              marginRight: 1,
                            }}
                          />
                          <Typography variant="p" marginRight={2}>
                            ending in {cardNumber}
                          </Typography>
                          <Typography variant="p" marginRight={1}>
                            {holderName}
                          </Typography>
                          <Typography variant="p">
                            {expMonth?.toString()?.padStart(2, 0)} / {expYear}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            onClick={() => {
                              deleteCard(id);
                              setSelectedPaymentMethod('');
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    )
                  )}
                </RadioGroup>
              </FormControl>
              <LoadingButton
                loading={addCardLoading}
                loadingPosition="start"
                startIcon={<AddIcon />}
                size="small"
                disabled={
                  !advertisementDate ||
                  Object.keys(errors).length ||
                  !isDirty ||
                  !isValid ||
                  !category
                }
                onClick={addNewCard}
              >
                Add Card
              </LoadingButton>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 1 }}>
          <LoadingButton
            type="submit"
            loading={submitPaymentLoading}
            size="small"
            disabled={
              !advertisementDate ||
              Object.keys(errors).length ||
              !isDirty ||
              !selectedPaymentMethod ||
              !isValid ||
              !category
            }
          >
            Submit a Payment
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default SingleDayAdvertisement;
