// ReusableAutoComplete.js
import React from 'react';
import Box from '../box';
import Typography from '../typography';
import Button from '../core/button';
import { useNavigate } from 'react-router-dom';

function Slides({ navKey, src, title, btn, btnDisabled, ...restProps }) {
  const navigate = useNavigate();

  return (
    <div className="item">
      <Box display="flex" alignItems="center">
        <Box position="absolute" paddingLeft="60px" sx={{ width: '50%' }}>
          <Typography
            variant="h3"
            lineHeight={1.5}
            color="#000"
            textTransform="uppercase"
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Button
            color="secondary"
            className="mt-4"
            size="large"
            disabled={btnDisabled}
            onClick={() => {
              navigate(navKey);
            }}
          >
            {btn}
          </Button>
        </Box>
        <img src={src} alt="banner-img" className="img-fluid" />
      </Box>
    </div>
  );
}

export default Slides;
