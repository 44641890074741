import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import {
  Alert,
  Box,
  Grid,
  InputAdornment,
  Typography,
  Button as MuiButton,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import TextInputControl from '../../../components/core/input/TextInputControl';
import ROUTES from '../../../utils/constant/routePaths';
import { resendOtp, verifyOtp } from '../services/authAPI';
import { updateUserData } from '../services/authSlice';

function EmailVerification() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(60);

  useEffect(() => {
    let countdown;
    if (isResendDisabled) {
      countdown = setInterval(() => {
        setSecondsRemaining((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    return () => clearInterval(countdown);
  }, [isResendDisabled]);

  useEffect(() => {
    if (secondsRemaining === 0) {
      setIsResendDisabled(false);
      setSecondsRemaining(60); // Reset the countdown
    }
  }, [secondsRemaining]);

  const formSchema = useMemo(
    () =>
      yup.object({
        otp: yup.string().required('OTP is required'),
      }),
    []
  );

  const { state: email } = location;
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      otp: '',
      email,
    },
  });

  const verifyEmail = (data) => {
    verifyOtp(data)
      .then((res) => {
        dispatch(updateUserData(res));
        const { user } = res;
        if (user?.role === 'provider') {
          const { businessLine: { key } = {} } = user;
          key === 'restaurants'
            ? navigate(ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT)
            : navigate(ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT);
        } else {
          navigate(ROUTES.USER.DASHBOARD);
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data.message, { variant: 'error' });
      });
  };

  const resendCode = () => {
    resendOtp(email)
      .then((res) => {
        setIsResendDisabled(true);
        enqueueSnackbar(res.message, {
          variant: res.status === 200 ? 'success' : 'error',
        });
      })
      .catch((err) => err);
  };

  useEffect(() => {
    if (!email) {
      navigate(ROUTES.USER.LOGIN);
    }
  }, []);

  return (
    <React.Fragment>
      <Box className="text-center mb-3">
        <Typography variant="h4">Email OTP Verification</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit(verifyEmail)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextInputControl
              id="otp"
              label="Enter One Time Password"
              type="text"
              control={control}
              error={Boolean(errors.otp)}
              rules={{ required: true }}
              fullWidth
              InputProps={{
                'aria-label': 'otp-input',
                endAdornment: (
                  <InputAdornment position="end">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Alert severity="warning">Please Check Your Email Inbox.</Alert>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button size="large" fullWidth type="submit">
              VERIFY
            </Button>
          </Grid>
          <Grid item xs={12} sm={6}>
            {isResendDisabled && (
              <Typography>
                Time remaining : {secondsRemaining.toString().padStart(2, '0')}
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} sm={6} textAlign={'end'}>
            <MuiButton
              variant="text"
              color="primary"
              disabled={isResendDisabled}
              onClick={resendCode}
              sx={{ padding: 0 }}
            >
              Resend Code
            </MuiButton>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default EmailVerification;
