// Input.js

import React from 'react';
import Box from '../box';
import Typography from '../typography';

function Work({ icon, title, subtitle, label, value, onChange, ...restProps }) {
  return (
    <Box textAlign="center" {...restProps} marginBottom={6}>
      <Box
        color="#4D8E7E"
        bgcolor="#fff"
        width="120px"
        height="120px"
        margin="auto"
        alignItems="center"
        justifyContent="center"
        display="inline-flex"
        borderRadius="80px"
        fontSize="64px"
        {...restProps}
      >
        {icon}
      </Box>
      <Typography variant="h4" fontWeight="bold" marginTop={3} {...restProps}>
        {title}
      </Typography>
      <Typography variant="h6" marginTop={1} {...restProps}>
        {subtitle}
      </Typography>
    </Box>
  );
}

export default Work;
