import React from 'react';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card, CardContent } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Container from '../../../components/container';
import Row from '../../../components/row';
import Col from '../../../components/col';
import Box from '../../../components/box';
import Header from '../../../components/header';
import Footer from '../../../components/footer';
import FormControls from '../../../components/formControl';
import AutoComplete from '../../../components/core/autocomplete/AutoComplete';
import Input from '../../../components/textField';
import Button from '../../../components/core/button';

function Wallet() {
  const dataCity = [
    { label: 'Ohio', year: 1994 },
    { label: 'New Jersey', year: 1994 },
    { label: 'New Work', year: 1994 },
    { label: 'New Wark', year: 1994 },
    { label: 'Washington', year: 1994 },
    { label: 'Orono', year: 1994 },
    { label: 'Philadelphia', year: 1994 },
  ];
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  return (
    <Container>
      {/* Header Start */}
      <Header />
      {/* Header End */}

      <Row justifyContent="center" marginTop={15} marginBottom={10}>
        <Col sm={10}>
          <Typography variant="h3" textAlign="center" marginBottom={5}>
            My Wallet
          </Typography>
          <Card sx={{ bgcolor: 'rgba(205, 224, 219, 0.50)' }}>
            <CardContent>
              <Box sx={{ flexGrow: 1, display: 'flex' }}>
                <Tabs
                  orientation="vertical"
                  variant="fixed"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                >
                  <Tab label="Add Card" {...a11yProps(0)} />
                  <Tab label="Saved Card" {...a11yProps(1)} />
                </Tabs>
                <TabPanel value={value} index={0}>
                  <Row justifyContent="center">
                    <Col xs={12}>
                      <Typography
                        variant="h4"
                        textAlign="center"
                        marginBottom={5}
                      >
                        Add Card
                      </Typography>
                    </Col>
                    <Col xs={12}>
                      <FormControls>
                        <Input
                          label="Name"
                          name="name"
                          // value={formData.firstName}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12}>
                      <FormControls>
                        <Input
                          label="Card Number"
                          name="cardNumber"
                          // value={formData.lastName}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={3}>
                      <FormControls>
                        <Input
                          label="MM"
                          name="number"
                          // value={formData.phone}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={3}>
                      <FormControls>
                        <Input
                          label="YYYY"
                          name="year"
                          type="number"
                          // value={formData.email}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={6}>
                      <FormControls>
                        <Input
                          label="CVV"
                          type="text"
                          name="address1"
                          // value={formData.password}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={12}>
                      <FormControls>
                        <Input
                          label="Billing Address"
                          type="text"
                          name="address2"
                          // value={formData.password}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={4}>
                      <FormControls>
                        <Input
                          label="City"
                          type="text"
                          name="city"
                          // value={formData.password}
                          // onChange={handleChange}
                        />
                        {/* <AutoComplete
                          label="City"
                          autoComplete={true}
                          options={dataCity}
                        /> */}
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={4}>
                      <FormControls>
                        <AutoComplete
                          label="State"
                          autoComplete
                          options={dataCity}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12} sm={4}>
                      <FormControls>
                        <Input
                          label="Zip Code"
                          name="zipCode"
                          // value={formData.zipCode}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12}>
                      <FormControls>
                        <Input
                          label="Phone"
                          name="phonr"
                          // value={formData.zipCode}
                          // onChange={handleChange}
                        />
                      </FormControls>
                    </Col>
                    <Col xs={12}>
                      <Button size="large" fullWidth>
                        Save
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Row justifyContent="center">
                    <Col xs={12}>
                      <Typography
                        variant="h4"
                        textAlign="center"
                        marginBottom={5}
                      >
                        Saved Card
                      </Typography>
                    </Col>
                    <Col xs={12} sm={12}>
                      <Card>
                        <CardContent>
                          <Row>
                            <Col xs>
                              <Typography variant="h6">
                                5266 3727 3254 9754
                              </Typography>
                            </Col>
                            <Col xs="auto">
                              <Button variant="outlined" color="primary">
                                <Edit />
                              </Button>
                            </Col>
                          </Row>
                          <Row marginTop={2}>
                            <Col xs>
                              <Typography variant="h6">
                                Lawrence Jackson
                              </Typography>
                            </Col>
                            <Col xs="auto">
                              <Typography variant="h6">06/26</Typography>
                            </Col>
                          </Row>
                        </CardContent>
                      </Card>
                    </Col>
                    <Col xs={12} marginTop={2}>
                      <Button size="large" fullWidth>
                        Add New Card
                      </Button>
                    </Col>
                  </Row>
                </TabPanel>
              </Box>
            </CardContent>
          </Card>
        </Col>
      </Row>

      {/* Header Start */}
      <Footer />
      {/* Header End */}
    </Container>
  );
}

export default Wallet;
