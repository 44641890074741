import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import {
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { clsx } from 'clsx';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import TextInputControl from '../../../components/core/input/TextInputControl';
import { DATE_TIME_FORMAT } from '../../../utils/constant/misc';
import { debounceFunc } from '../../../utils/helper/utility';
import { validateZipCode } from '../../auth/services/authAPI';

import { useSelector } from 'react-redux';
import '../../../assets/styles/plan.css';
import { createRestaurantServiceRequest } from '../services/cuisineApi';

function AddCuisinePlan(props) {
  const { cuisines, open, handleClose, getCuisinePlans } = props;
  const userInfo = useSelector((state) => state.auth.userData);

  const [cuisineKey, setCuisineKey] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isValidatingZipCode, setIsValidatingZipCode] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        street: yup.string().required('Street address is required'),
        // aroundInMiles: yup
        //   .number()
        //   .typeError('Distance in miles must be a number')
        //   .required('Distance in miles is required')
        //   .min(1, 'Distance in miles must be grater than 0.')
        //   .max(25, 'Distance in miles must be less than or equal to 25.'),
        zipCode: yup
          .number()
          .typeError('Valid zip code is required!')
          .required('Valid zip code is required!'),
        state: yup.string().required('State is required'),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    watch,
    setError,
    resetField,
    clearErrors,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      businessLineKey: 'restaurants',
      aroundInMiles: 0,
      guestCount: 5,
      street: '',
      city: '',
      state: '',
      country: 'US',
      zipCode: '',
      timezone: '',
    },
  });

  const zipCode = watch('zipCode');

  const handleChange = (value) => {
    if (value) {
      validateZipCode('US', value).then((res) => {
        setIsValidatingZipCode(false);
        const { city, state, timzones, statusCode, message } = res;
        if (statusCode === 400) {
          setError('zipCode', { message });
          resetField('state');
          return;
        }
        clearErrors('zipCode');
        setValue('city', city);
        setValue('timezone', timzones[0]);
        setValue('state', state);
      });
    }
  };

  const optimizedFn = useCallback(debounceFunc(handleChange), []);

  useEffect(() => {
    setIsValidatingZipCode(true);
    optimizedFn(zipCode);
  }, [zipCode]);

  const addCuisinePlan = (data) => {
    if (!cuisineKey) {
      setCuisineKey('error');
      enqueueSnackbar(
        'Please select the desired cuisine from the options provided.',
        {
          variant: 'error',
        }
      );
      return;
    }
    data.cuisineKey = cuisineKey;
    data.durationFrom = fromDate.format(DATE_TIME_FORMAT);
    data.durationTo = toDate.endOf('day').format(DATE_TIME_FORMAT);

    createRestaurantServiceRequest(userInfo._id, data)
      .then((res) => {
        if (res._id) {
          enqueueSnackbar(
            'Dear Customer, thank you for creating a service plan. Please be sure to check your email regularly, as we will notify you as soon as we have the deals ready for you.',
            { variant: 'success', autoHideDuration: 11000 }
          );
          reset();
          setCuisineKey('');
          setFromDate(null);
          setToDate(null);
          handleClose();
          getCuisinePlans();
        }
      })
      .catch((err) => {
        console.log('🚀 ~ addCuisinePlan ~ err:', err);
      });
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(addCuisinePlan),
      }}
      maxWidth="md"
    >
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Typography variant="h4" color="#4F8D7D">
              Add Cuisine Plan
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={4} sm={2} md={2} marginTop={1}>
            {cuisines?.map((c) => (
              <Button
                key={c._id}
                size="sm"
                color="primary"
                className={clsx(
                  'mb-2',
                  cuisineKey === c.key && 'active-cuisine',
                  cuisineKey === 'error' && 'error-cuisine'
                )}
                sx={{ minWidth: '110px' }}
                onClick={() => {
                  setCuisineKey(c.key);
                }}
              >
                {c.name}
              </Button>
            ))}
          </Grid>
          <Grid item xs={8} sm={10} md={10} marginTop={1}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    format="MM/DD/YYYY"
                    label="From Date"
                    sx={{ width: '100%' }}
                    value={fromDate}
                    onChange={(value) => {
                      setFromDate(value);
                      setToDate(null);
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    disablePast
                    format="MM/DD/YYYY"
                    label="To Date"
                    sx={{ width: '100%' }}
                    value={toDate}
                    onChange={(value) => {
                      setToDate(value);
                    }}
                    shouldDisableDate={(date) => {
                      return (
                        fromDate &&
                        !dayjs(date).isBetween(
                          fromDate,
                          fromDate.clone().add(6, 'days'),
                          'day',
                          '[]'
                        )
                      );
                    }}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} marginTop={2}>
              <TextInputControl
                control={control}
                error={Boolean(errors.street)}
                id="street"
                label="Street Address"
                rules={{ required: true }}
                fullWidth
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} marginTop={2}>
                <TextInputControl
                  control={control}
                  error={Boolean(errors.zipCode)}
                  id="zipCode"
                  label="Zip Code"
                  rules={{ required: true }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6} marginTop={2}>
                <TextInputControl
                  control={control}
                  error={Boolean(errors.state)}
                  id="state"
                  label="State"
                  rules={{ required: true }}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            {/* <Grid item xs={12} sm={12} marginTop={2}>
              <TextInputControl
                control={control}
                error={Boolean(errors.aroundInMiles)}
                id="aroundInMiles"
                label="Distance From Restaurant In Miles"
                rules={{ required: true }}
                fullWidth
              />
            </Grid> */}

            <Grid item xs={12} sm={12} marginTop={2}>
              <Alert severity="info">
                Maximum of three plans can be activated at any given time.
              </Alert>
            </Grid>
            <Grid item xs={12} sm={12} marginTop={2}>
              <Alert severity="info">
                The duration of from and to date period should not exceed one
                week.
              </Alert>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ margin: 1 }}>
        <Button
          type="submit"
          disabled={
            !fromDate ||
            !toDate ||
            isValidatingZipCode ||
            Object.keys(errors).length ||
            !isDirty
          }
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddCuisinePlan;
