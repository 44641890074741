import Buttons from '@mui/material/Button';

function Button({ children, variant, color, onClick, type, ...restProps }) {
  return (
    <Buttons
      sx={{ fontSize: 18 }}
      variant={variant ? '' : 'contained'}
      color={color}
      onClick={onClick}
      type={type}
      {...restProps}
    >
      {children}
    </Buttons>
  );
}

export default Button;
