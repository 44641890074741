import { Email } from '@mui/icons-material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/images/savyomo-logo.svg';
import MerchantPrivacyPolicy from '../../module/auth/components/MerchantPrivacyPolicy';
import MerchantTermsConditions from '../../module/auth/components/MerchantTermsConditions';
import PrivacyPolicy from '../../module/auth/components/PrivacyPolicy';
import TermsConditions from '../../module/auth/components/TermsConditions';
import ROUTES from '../../utils/constant/routePaths';
import Box from '../box';
import Col from '../col';
import Row from '../row';

function Footer() {
  const navigate = useNavigate();

  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  const userInfo = useSelector((state) => state.auth.userData);

  return (
    <>
      {userInfo?.role === 'provider' ? (
        <>
          <MerchantPrivacyPolicy
            showPrivacyPolicyPopup={showPrivacyPopup}
            handleClose={() => {
              setShowPrivacyPopup(false);
            }}
          />
          <MerchantTermsConditions
            showTermsPopup={showTermsPopup}
            handleClose={() => {
              setShowTermsPopup(false);
            }}
          />
        </>
      ) : (
        <>
          <PrivacyPolicy
            showPrivacyPolicyPopup={showPrivacyPopup}
            handleClose={() => {
              setShowPrivacyPopup(false);
            }}
          />
          <TermsConditions
            showTermsPopup={showTermsPopup}
            handleClose={() => {
              setShowTermsPopup(false);
            }}
          />
        </>
      )}

      <Box bgcolor="#0A0F17" color="#b9b9b9" padding={8} paddingBottom={4}>
        <Row noSpace>
          <Col sm={3}>
            <Logo
              width={260}
              onClick={() => {
                if (userInfo?.role === 'consumer') {
                  navigate(ROUTES.USER.DASHBOARD);
                }
              }}
              style={{ cursor: 'pointer' }}
            />
            <Typography variant="h6" fontWeight="300" width={280} marginTop={3}>
              With our incredible & flexible savings opportunities, you can keep
              more of your hard-earned cash in your pocket.
            </Typography>
          </Col>
          <Col sm={3}>
            <Typography variant="h5">About</Typography>
            <List>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="About us" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="Careers" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="Blog" />
                </ListItemButton>
              </ListItem>
              <ListItem
                className="p-0"
                onClick={() => {
                  setShowPrivacyPopup(true);
                }}
              >
                <ListItemButton className="p-0">
                  <ListItemText primary="Privacy Policy" />
                </ListItemButton>
              </ListItem>
              <ListItem
                className="p-0"
                onClick={() => {
                  setShowTermsPopup(true);
                }}
              >
                <ListItemButton className="p-0">
                  <ListItemText primary="Terms & Condition" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="Help" />
                </ListItemButton>
              </ListItem>
            </List>
          </Col>

          <Col sm={3}>
            <Typography variant="h5">Categories</Typography>
            <List>
              <ListItem className="p-0">
                <ListItemButton
                  className="p-0"
                  onClick={() => {
                    userInfo.role === 'consumer' &&
                      navigate(ROUTES.USER.RESTAURANTS.CUISINE_PLAN);
                  }}
                >
                  <ListItemText primary="Restaurants" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton
                  className="p-0"
                  onClick={() => {
                    userInfo.role === 'consumer' &&
                      navigate(ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN);
                  }}
                >
                  <ListItemText primary="Automotive Services" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="Air Tickets" />
                </ListItemButton>
              </ListItem>
              <ListItem className="p-0">
                <ListItemButton className="p-0">
                  <ListItemText primary="Hotel & Motels" />
                </ListItemButton>
              </ListItem>
            </List>
          </Col>

          <Col sm={3}>
            <Typography variant="h5">Contact</Typography>
            <List>
              <ListItem className="p-0">
                <Box color="#4D8E7E" paddingRight={1}>
                  {/* <LocationOn /> */}
                </Box>
                {/* <ListItemButton> */}
                {/* <ListItemText primary="2688 D StreetFlint, MI 48548" /> */}
                {/* </ListItemButton> */}
              </ListItem>
              <ListItem className="p-0">
                <Box color="#4D8E7E" paddingRight={1}>
                  <Email />
                </Box>
                {/* <ListItemButton> */}
                <ListItemText primary="info@savyomo.com" />
                {/* </ListItemButton> */}
              </ListItem>
            </List>
          </Col>
        </Row>
        <Box sx={{ borderTop: 1, borderColor: '#3B3F45', marginTop: 5 }}>
          <Typography variant="h6" paddingTop={2}>
            © 2024 Savyomo.com. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Footer;
