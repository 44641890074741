// ReusableGrid.js
import React from 'react';
import Grid from '@mui/material/Grid';

function Row({ noSpace, children, columnSpacing, ...restProps }) {
  return (
    <Grid container {...restProps} columnSpacing={noSpace ? 0 : 2}>
      {children}
    </Grid>
  );
}

export default Row;
