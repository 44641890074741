import { axiosVerifiedAuthInstance } from '../../../utils/helper/axiosInstances';

export const getRestaurantAdvertisementsByZipCodes = async (type, data) =>
  await axiosVerifiedAuthInstance
    .post(`/restaurants/advertisements/${type}`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const getAutomotiveAdvertisementsByZipCodes = async (type, data) =>
  await axiosVerifiedAuthInstance
    .post(`/automotive/advertisements/${type}`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const availablePromoCodes = async (id, type) =>
  await axiosVerifiedAuthInstance
    .get(`/${type}/available-promo-codes/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const purchasePromoCodes = async (id, type, data) =>
  await axiosVerifiedAuthInstance
    .post(`/${type}/purchase-promo-codes/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err);
