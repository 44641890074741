import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';

function PromoCodeInfo({ promoCodes, showInfoPopup, handleClose }) {
  return (
    <Dialog open={showInfoPopup}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Typography variant="h6" color="#4F8D7D">
              !! IMPORTANT MESSAGE !!
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            <Typography>
              Thank you for purchasing the promo code. Please make sure to save
              the promo code provided below for verification purposes when
              redeeming at the store. We will also provide you with the promo
              code through email.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography>Code : </Typography>{' '}
          </Grid>

          {promoCodes.map((code, i) => (
            <Grid key={i + 1} item xs={6} sm={3}>
              <Typography>
                {i + 1}. {code}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PromoCodeInfo;
