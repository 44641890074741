import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '../../../components/container';
import Button from '../../../components/core/button';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import AddAutoServicePlan from '../components/AddAutoServicePlan';
import {
  deleteAutomotiveServiceRequest,
  fetchAutomotiveServiceRequest,
  getAutoMotiveServices,
} from '../services/autoServiceApi';

function AutoMotivePlan() {
  const userInfo = useSelector((state) => state.auth.userData);

  const [open, setOpen] = useState(false);
  const [automotiveServices, setAutomotiveServices] = useState([]);
  const [automotiveServiceRequests, setAutomotiveServiceRequests] = useState(
    []
  );

  const getAutomotiveServices = async () => {
    await getAutoMotiveServices()
      .then((res) => {
        setAutomotiveServices(res.autoServices);
      })
      .catch((err) => err);
  };

  const getAutomotiveServiceRequests = async () => {
    await fetchAutomotiveServiceRequest(userInfo._id).then((res) => {
      const plans = res?.map(
        ({ _id, service, durationFrom, durationTo, timezone, zipCode }) => ({
          id: _id,
          name: service.name,
          fromDate: dayjs(durationFrom).tz(timezone).format('MMMM DD, YYYY'),
          toDate: dayjs(durationTo).tz(timezone).format('MMMM DD, YYYY'),
          zipCode,
        })
      );
      setAutomotiveServiceRequests([...plans]);
    });
  };

  const deleteAutoServicePlan = (id) => {
    deleteAutomotiveServiceRequest(userInfo._id, id).then(async (res) => {
      if (res.statusCode === 200) {
        enqueueSnackbar(res.message, { variant: 'success' });
        await getAutomotiveServiceRequests();
      }
    });
  };

  useEffect(() => {
    getAutomotiveServices();
    getAutomotiveServiceRequests();
  }, []);

  return (
    <Container className="bg-automotive">
      <Header />
      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={15}
        marginBottom={10}
      >
        <Grid item xs={12} sm={9}>
          <AddAutoServicePlan
            autoServices={automotiveServices}
            open={open}
            handleClose={() => {
              setOpen(false);
            }}
            getAutoServiceRequests={getAutomotiveServiceRequests}
          />
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h4" color="#4F8D7D" marginBottom={5}>
                    My Auto Service Plans
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign="right">
                  <Button
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                    }}
                    disabled={automotiveServiceRequests.length >= 3}
                  >
                    <AddIcon /> Add Auto Service Plan
                  </Button>
                </Grid>
              </Grid>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Auto Service</TableCell>
                    <TableCell align="center">From Date</TableCell>
                    <TableCell align="center">To Date</TableCell>
                    <TableCell align="center">Zip Code</TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                {automotiveServiceRequests.length ? (
                  <TableBody>
                    {automotiveServiceRequests.map(
                      ({ id, name, fromDate, toDate, zipCode }) => (
                        <TableRow
                          key={name}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {name}
                          </TableCell>
                          <TableCell align="center">{fromDate}</TableCell>
                          <TableCell align="center">{toDate}</TableCell>
                          <TableCell align="center">{zipCode}</TableCell>
                          <TableCell align="center">
                            <Button
                              color="error"
                              variant="text"
                              onClick={() => deleteAutoServicePlan(id)}
                            >
                              <CloseIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                ) : (
                  <TableBody>
                    <TableRow
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell colSpan={4} align="center">
                        <Typography component={'h6'}>
                          No records found.
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}

export default AutoMotivePlan;
