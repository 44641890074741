// ReusableGrid.js
import React from 'react';
import Containers from '@mui/material/Container';

function Container({ fullWidth, children, ...restProps }) {
  return (
    <Containers {...restProps} disableGutters maxWidth={fullWidth ? '' : 'xl'}>
      {children}
    </Containers>
  );
}

export default Container;
