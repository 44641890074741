import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';

import Button from '../../../components/core/button';

function PrivacyPolicy({ showPrivacyPolicyPopup, handleClose }) {
  return (
    <Dialog open={showPrivacyPolicyPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Typography variant="h6" color="#4F8D7D">
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            <Typography mb={1}>
              This Privacy Policy describes how Savyomo (&quot;we,&quot;
              &quot;us,&quot; &quot;our&quot;) collects, uses, and shares
              personal information of users who sign up on our website.
            </Typography>
            <Typography mb={1}>
              By utilizing the Service, you are confirming that you have
              reviewed the terms outlined in this Privacy Notice. If you do not
              wish for your information to be processed in accordance with this
              Privacy Notice, kindly refrain from providing us with your
              information or engaging with the Service.
            </Typography>
            <Typography mb={2}>Effective as of March 28, 2024</Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Information We Collect
            </Typography>
            <Typography>
              When you sign up for our service, we may collect the following
              information:
            </Typography>
            <Typography>
              <ul style={{ padding: '1rem', paddingInlineStart: '2rem' }}>
                <li>
                  Personal Information: This includes your name, email address,
                  phone number, postal address, and contact details.
                </li>
                <li>
                  Account Information: This refers to your username, password,
                  and other login credentials.
                </li>
                <li>
                  Payment Information: Includes credit card or other payment
                  details.
                </li>
                <li>
                  Usage Information: Details about how you use our services,
                  including interactions with our website and features.
                </li>
                <li>
                  Lifestyle and Interest Information: Information that helps us
                  determine your lifestyle, interests, activities, purchasing
                  tendencies, and order history.
                </li>
                <li>
                  Internet and Network Activity Information: Data related to
                  your online activity.
                </li>
                <li>
                  Device Information: Details about the devices you use to
                  access our service, such as hardware model, operating system,
                  IP address, unique identifiers, mobile network information,
                  and interaction with our service. This information helps us
                  analyze usage patterns and improve our service.
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              How We Use Your Information
            </Typography>
            <Typography>
              We may use the information we collect for various purposes,
              including:
            </Typography>
            <Typography>
              <ul style={{ padding: '1rem', paddingInlineStart: '2rem' }}>
                <li>
                  To continuously improve and optimize our services for our
                  valued clients.
                </li>
                <li>
                  To engage in regular communication with you regarding your
                  account status and the services we provide.
                </li>
                <li>To efficiently process payments and transactions.</li>
                <li>
                  To personalize your experience by offering tailored content
                  and promotions that align with your preferences.
                </li>
                <li>
                  To promptly address any inquiries and provide top-notch
                  customer support.
                </li>
                <li>
                  To deliver targeted advertisements, notifications, and updates
                  based on your interests and location.
                </li>
                <li>
                  To identify, address, and prevent technical issues or security
                  threats.
                </li>
                <li>
                  To comply with legal regulations and maintain adherence to our
                  company policies.
                </li>
                <li>
                  To determine your eligibility for specific offers, products,
                  or services, and evaluate the effectiveness of advertising
                  strategies.
                </li>
                <li>
                  To conduct analytics and customer research for strategic
                  decision-making.
                </li>
                <li>
                  To handle operational tasks such as service management, forum
                  moderation, order processing, data analysis, fraud detection,
                  and corporate reporting.
                </li>
                <li>
                  To gain insight into your interests and preferences by
                  analyzing additional information.
                </li>
                <li>
                  For any other purposes that require your explicit consent.
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Sharing Your Personal Information
            </Typography>
            <Typography mb={2}>
              We value the privacy of our users and do not disclose or exchange
              their personal information with outside parties. Any generalized
              demographic data that is shared with our business partners,
              trusted affiliates, and advertisers is done so without any
              personal identification information attached. This information is
              only utilized for the purposes previously stated.{' '}
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Retention of Personal Data
            </Typography>
            <Typography mb={2}>
              Our company will securely maintain your personal data for the
              duration of your account&apos;s activity or as required for the
              provision of services to you. Should you choose to close your
              account, we will continue to retain your personal information for
              a period necessary for the effective operation of our business, to
              keep a record of your transactions for financial reporting or
              fraud prevention purposes, until such time that these needs are no
              longer relevant. Furthermore, we will retain your data as required
              to fulfill legal obligations, settle disputes, and enforce
              agreements.{' '}
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              How We Protect Your Information
            </Typography>
            <Typography mb={2}>
              We implement secure data collection, storage, and processing
              protocols along with robust security measures to safeguard against
              unauthorized access, alteration, disclosure, or destruction of
              personal information, usernames, passwords, transaction details,
              and data stored on our platform.
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Changes to This Privacy Policy
            </Typography>
            <Typography mb={2}>
              We may update this Privacy Policy from time to time. We may or may
              not notify you of any changes by posting the new Privacy Policy on
              this page.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default PrivacyPolicy;
