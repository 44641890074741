import { axiosVerifiedAuthInstance } from '../../../utils/helper/axiosInstances';

export const getAutoMotiveServices = async () =>
  await axiosVerifiedAuthInstance
    .get('/automotive/services')
    .then((res) => res.data)
    .catch((err) => err);

export const createAutomotiveServiceRequest = async (userId, data) =>
  await axiosVerifiedAuthInstance
    .post(`/consumers/${userId}/automotive/service-requests`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchAutomotiveServiceRequest = async (userId) =>
  await axiosVerifiedAuthInstance
    .get(`/consumers/${userId}/automotive/service-requests`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteAutomotiveServiceRequest = async (
  userId,
  serviceRequestId
) =>
  await axiosVerifiedAuthInstance
    .delete(
      `/consumers/${userId}/automotive/service-requests/${serviceRequestId}`
    )
    .then((res) => res.data)
    .catch((err) => err);
