import { Box, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from '../../../../utils/constant/routePaths';
import LoginForm from '../../components/LoginForm';

function MerchantLogin() {
  return (
    <React.Fragment>
      <Box className="text-center mb-3">
        <Typography variant="h4" paddingTop={'10px'}>
          Merchant Sign In
        </Typography>
        <Typography variant="h6" paddingTop={'10px'}>
          {`Don't have an account?`}
          <Link color="primary" to={ROUTES.MERCHANT.SIGN_UP}>
            <b> Sign Up </b>
          </Link>
          , It takes less than a minute.
        </Typography>
      </Box>
      <LoginForm role="provider" />
    </React.Fragment>
  );
}

export default MerchantLogin;
