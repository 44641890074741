import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';

import Button from '../../../components/core/button';

function MerchantPrivacyPolicy({ showPrivacyPolicyPopup, handleClose }) {
  return (
    <Dialog open={showPrivacyPolicyPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Typography variant="h6" color="#4F8D7D">
              Privacy Policy
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} mb={2}>
          <Grid item xs={12} sm={12}>
            <Typography mb={1}>
              This Privacy Policy describes how Savyomo (&quot;we,&quot;
              &quot;us,&quot; &quot;our&quot;) collects, uses, and shares
              personal information of users who sign up for our services through
              our website.
            </Typography>
            <Typography mb={1}>
              By utilizing the Service, you are confirming that you have
              reviewed the terms outlined in this Privacy Notice. If you do not
              wish for your information to be processed in accordance with this
              Privacy Notice, kindly refrain from providing us with your
              information or engaging with the Service.
            </Typography>
            <Typography mb={2}>Effective as of March 28, 2024</Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Information We Collect
            </Typography>
            <Typography>
              When you sign up for our service, we may collect the following
              information:
            </Typography>
            <Typography>
              <ul style={{ padding: '1rem', paddingInlineStart: '2rem' }}>
                <li>
                  Personal Information: such as your name, email address, phone
                  number, postal address, and other contact information.
                </li>
                <li>
                  Business Information: such as your business name, business
                  address, business type, and other relevant business details.{' '}
                </li>
                <li>
                  Account Information: such as your username, password, and
                  other credentials used to access our services.
                </li>
                <li>
                  Payment Information: such as your credit card or other payment
                  details.{' '}
                </li>
                <li>
                  Usage Information: such as information about how you use our
                  services, including your interactions with our website and
                  features.
                </li>
                <li>
                  Information that enables us to determine lifestyle, interests,
                  and activities, including purchasing tendencies and order
                  history, areas of interest.
                </li>
                <li>Internet and network activity information.</li>
                <li>
                  Information about the devices you use to access the Service,
                  including hardware model, operating system and version,
                  Internet Protocol (“IP”) address, and other unique device
                  identifiers, mobile network information, and information about
                  the device’s interaction with our Service. We also collect
                  information about how you use our Service, including your
                  access times, browser types, and language. This type of data
                  enables us to understand how often individuals use parts of
                  the Service so we can analyze and improve it.
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              How We Use Your Information
            </Typography>
            <Typography>
              We may use the information we collect for various purposes,
              including:
            </Typography>
            <Typography>
              <ul style={{ padding: '1rem', paddingInlineStart: '2rem' }}>
                <li>
                  In order to provide, maintain, and enhance the quality of our
                  services.
                </li>
                <li>
                  To keep you informed about your account and the services we
                  offer.
                </li>
                <li>To handle your payments and transactions efficiently.</li>
                <li>
                  To customize your experience and tailor content and offers
                  according to your preferences.
                </li>
                <li>To address your inquiries and offer customer support.</li>
                <li>
                  To provide you with personalized ads, notifications,
                  communications, and offers from us and our partners, taking
                  into account your precise location.
                </li>
                <li>
                  To identify, prevent, and resolve technical issues or security
                  threats.
                </li>
                <li>
                  To fulfill legal obligations and uphold our terms and
                  policies.
                </li>
                <li>For any other purposes with your consent.</li>
                <li>
                  To assess your eligibility for various offers, products, or
                  services that may be of interest to you, and evaluate the
                  effectiveness of advertising.
                </li>
                <li>To conduct analysis and customer research.</li>
                <li>
                  To carry out essential business operations, including
                  administration of the service, forum management, analytics,
                  fraud prevention, and compliance with corporate reporting
                  requirements.
                </li>
                <li>
                  To enrich existing information about you to gain a better
                  understanding of your preferences and interests.
                </li>
              </ul>
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Sharing Your Personal Information
            </Typography>
            <Typography mb={2}>
              We maintain strict confidentiality of Users personal
              identification information and do not engage in selling, trading,
              or renting such information to third parties. Any generic
              aggregated demographic information that is shared with our
              business partners, trusted affiliates, and advertisers is not
              linked to any personal identification information of visitors and
              users. This sharing is done for the purposes outlined above.
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Retention of Personal Data
            </Typography>
            <Typography mb={2}>
              We will securely maintain your personal data for the duration of
              your active account or as required to uphold our contractual
              obligations. In the event of account closure, we will retain your
              personal data for a reasonable period necessary for legitimate
              business operations, financial record-keeping, fraud prevention,
              legal compliance, dispute resolution, and contract enforcement.
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              How We Protect Your Information
            </Typography>
            <Typography mb={2}>
              We implement suitable data collection, storage, and processing
              protocols with security measures to safeguard against unauthorized
              access, alteration, disclosure, or destruction of personal
              information, usernames, passwords, transaction details, and data
              housed on our platform.
            </Typography>

            <Typography variant="h6" color="#4F8D7D" my={1}>
              Changes to This Privacy Policy
            </Typography>
            <Typography mb={2}>
              We may update this Privacy Policy from time to time. We may or may
              not notify you of any changes by posting the new Privacy Policy on
              this page.
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default MerchantPrivacyPolicy;
