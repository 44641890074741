import AddIcon from '@mui/icons-material/Add';
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  TableContainer,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Container from '../../../components/container';
import Button from '../../../components/core/button';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import {
  deletePaymentMethod,
  getAttachedCards,
} from '../../payment/services/paymentApi';
import { getRestaurantsCuisines } from '../../restaurant/services/cuisineApi';
import MultipleDayAdvertisement from '../components/MultipleDayAdvertisement';
import SingleDayAdvertisement from '../components/SingleDayAdvertisement';
import SuccessPopup from '../components/SuccessPopup';
import {
  createRestaurantAdvertisement,
  getRestaurantAdvertisements,
} from '../services/merchantApi';

function RestaurantAdvertisement() {
  const userInfo = useSelector((state) => state.auth.userData);

  const [cuisines, setCuisines] = useState([]);
  const [showOneDayPopup, setShowOneDayPopup] = useState(false);
  const [showMultiDayPopup, setShowMultiDayPopup] = useState(false);
  const [singleDayAdvertisement, setSingleDayAdvertisement] = useState([]);
  const [multipleDayAdvertisement, setMultipleDayAdvertisement] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const fetchAdvertisements = (type) => {
    const { provider: { _id: providerId } = {} } = userInfo;
    getRestaurantAdvertisements(providerId, type)
      .then((res) => {
        type === 'SINGLE_DAY'
          ? setSingleDayAdvertisement([...res])
          : setMultipleDayAdvertisement([...res]);
      })
      .catch((err) => err);
  };

  const fetchCards = () => {
    getAttachedCards(userInfo.customerId)
      .then((res) => {
        const { data } = res;
        if (data) setPaymentMethods([...data]);
      })
      .catch((err) => err);
  };

  const deleteCard = (paymentMethodId) => {
    deletePaymentMethod(paymentMethodId).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        fetchCards();
      }
    });
  };

  const addAdvertisement = async (providerId, reqData, singleDay) => {
    return new Promise((resolve) => {
      createRestaurantAdvertisement(providerId, reqData).then((res) => {
        const { statusCode, message } = res;
        enqueueSnackbar(message, {
          variant: statusCode === 200 ? 'success' : 'error',
        });
        if (statusCode === 200) {
          if (singleDay) {
            setShowOneDayPopup(false);
            fetchAdvertisements('SINGLE_DAY');
            setShowSuccessPopup(true);
          } else {
            setShowMultiDayPopup(false);
            fetchAdvertisements('MULTIPLE_DAY');
          }
        }
        resolve(statusCode === 200);
      });
    });
  };

  const fetchRestaurantsCuisines = () => {
    getRestaurantsCuisines()
      .then((res) => {
        setCuisines(res.cuisines);
      })
      .catch((err) => err);
  };

  useEffect(() => {
    fetchRestaurantsCuisines();
    fetchCards();
    fetchAdvertisements('MULTIPLE_DAY');
    fetchAdvertisements('SINGLE_DAY');
  }, []);

  return (
    <Container>
      <Header />
      <Grid container spacing={2} justifyContent="center" marginTop={2}>
        <Grid item xs={12} sm={10}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            marginTop={10}
            marginBottom={10}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" fontWeight="bold" marginBottom={2}>
                Why One Day Advertisement ?
              </Typography>
              <Card
                sx={{
                  backgroundColor: '#98C2B9',
                  color: '#fff',
                }}
              >
                <CardContent
                  sx={{ padding: 3 }}
                  className="one-day-advertisement-box"
                >
                  <Typography lineHeight={1.8} fontSize="18px">
                    Are you interested in finding opportunities to minimize
                    waste and maximize revenue for perishable items with limited
                    storage lifespan? It would be advantageous for your business
                    to prioritize daily freshness for customers by implementing
                    strategies that involve offering discounted prices on
                    nearing expiration goods, rather than holding onto them for
                    an extended period.
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 3 }}>
                  <Button
                    color="light"
                    sx={{ color: '#4F8D7D', backgroundColor: '#fff' }}
                    onClick={() => {
                      setShowOneDayPopup(true);
                    }}
                  >
                    <AddIcon /> Create One Day Advertisement
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h4" fontWeight="bold" marginBottom={2}>
                Why Multiple Days Advertisement ?
              </Typography>
              <Card
                sx={{
                  backgroundColor: '#98C2B9',
                  color: '#fff',
                }}
              >
                <CardContent
                  sx={{ padding: 3 }}
                  className="multiple-day-advertisement-box"
                >
                  <Typography fontSize="18px" lineHeight={1.8}>
                    Are you interested in growing your customer base and
                    boosting your revenue by offering your food at discounted
                    rates through extended promotional campaigns? Implementing
                    longer-term marketing strategies can significantly enhance
                    your company revenue and profitability. Optimize the
                    potential of this campaign to enhance your financial gains.
                  </Typography>
                </CardContent>
                <CardActions sx={{ padding: 3 }}>
                  <Button
                    color="light"
                    sx={{ color: '#4F8D7D', backgroundColor: '#fff' }}
                    onClick={() => {
                      setShowMultiDayPopup(true);
                    }}
                  >
                    <AddIcon /> Create Multiple Days Advertisement
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <SuccessPopup
        showSuccessPopup={showSuccessPopup}
        handleClose={() => {
          setShowSuccessPopup(false);
        }}
      />

      <Grid
        container
        spacing={2}
        justifyContent="center"
        marginTop={5}
        marginBottom={10}
        paddingTop={5}
        paddingBottom={7}
        className="bg-restaurant"
      >
        <Grid item xs={12} sm={10}>
          <SingleDayAdvertisement
            type="restaurant"
            categories={cuisines}
            paymentMethods={paymentMethods}
            open={showOneDayPopup}
            handleClose={() => {
              setShowOneDayPopup(false);
            }}
            deleteCard={deleteCard}
            setPaymentMethods={setPaymentMethods}
            addAdvertisement={addAdvertisement}
          />
          <MultipleDayAdvertisement
            type="restaurant"
            categories={cuisines}
            paymentMethods={paymentMethods}
            open={showMultiDayPopup}
            handleClose={() => {
              setShowMultiDayPopup(false);
            }}
            deleteCard={deleteCard}
            setPaymentMethods={setPaymentMethods}
            addAdvertisement={addAdvertisement}
          />

          {/* One day Advertisement */}
          <Card sx={{ mb: 5 }}>
            <CardContent>
              <Typography
                variant="h4"
                textAlign="center"
                color="#4F8D7D"
                marginBottom={3}
                fontWeight="bold"
              >
                One Day Advertisement
              </Typography>
              <TableContainer
                sx={{ minWidth: 650, maxHeight: '400px', overflowY: 'auto' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Offer Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Category Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Quantity
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Paid Amount
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {singleDayAdvertisement?.length ? (
                    <TableBody>
                      {singleDayAdvertisement.map(
                        (
                          {
                            name,
                            cuisine,
                            timezone,
                            durationFrom,
                            durationTo,
                            advertisementCost,
                            processingFee,
                            quantity,
                          },
                          index
                        ) => (
                          <TableRow
                            key={index + 1}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {cuisine.name}
                            </TableCell>
                            <TableCell>
                              {dayjs(durationFrom)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>{quantity}</TableCell>
                            <TableCell>
                              ${advertisementCost + processingFee}
                            </TableCell>

                            <TableCell>
                              <Typography color={'red'}>
                                {dayjs(durationTo).isBefore(dayjs.utc()) &&
                                  'Expired'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={5} align="center">
                          <Typography component={'h6'}>
                            No records found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>

          {/* Multi day Advertisement */}
          <Card>
            <CardContent>
              <Typography
                variant="h4"
                textAlign="center"
                color="#4F8D7D"
                marginBottom={3}
                fontWeight="bold"
              >
                Multiple Day Advertisement
              </Typography>
              <TableContainer
                sx={{ minWidth: 650, maxHeight: '400px', overflowY: 'auto' }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Offer Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Category Name
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        From Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        To Date
                      </TableCell>
                      <TableCell sx={{ color: '#4F8D7D', fontSize: '16px' }}>
                        Paid Amount
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>
                  {multipleDayAdvertisement?.length ? (
                    <TableBody>
                      {multipleDayAdvertisement.map(
                        (
                          {
                            name,
                            cuisine,
                            timezone,
                            durationFrom,
                            durationTo,
                            advertisementCost,
                            processingFee,
                          },
                          index
                        ) => (
                          <TableRow
                            key={index + 1}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {cuisine.name}
                            </TableCell>

                            <TableCell>
                              {dayjs(durationFrom)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>
                              {dayjs(durationTo)
                                .tz(timezone)
                                .format('MMMM DD, YYYY')}
                            </TableCell>
                            <TableCell>
                              ${advertisementCost + processingFee}
                            </TableCell>
                            <TableCell>
                              <Typography color={'red'}>
                                {dayjs(durationTo).isBefore(dayjs.utc()) &&
                                  'Expired'}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  ) : (
                    <TableBody>
                      <TableRow
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell colSpan={5} align="center">
                          <Typography component={'h6'}>
                            No records found.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
}

export default RestaurantAdvertisement;
