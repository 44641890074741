import { axiosVerifiedMerchantAuthInstance } from '../../../utils/helper/axiosInstances';

export const createPaymentIntent = async (data) =>
  await axiosVerifiedMerchantAuthInstance
    .post('/payments/create-intent', data)
    .then((res) => res.data)
    .catch((err) => err);

export const attachPaymentMethod = async (data) =>
  await axiosVerifiedMerchantAuthInstance
    .post('/payments/attach-card', data)
    .then((res) => res.data)
    .catch((err) => err);

export const deletePaymentMethod = async (paymentMethodId) =>
  await axiosVerifiedMerchantAuthInstance
    .delete(`/payments/${paymentMethodId}`)
    .then((res) => res.data)
    .catch((err) => err);

export const getAttachedCards = async (customerId) =>
  await axiosVerifiedMerchantAuthInstance
    .get(`/payments/fetch-cards?customerId=${customerId}`)
    .then((res) => res.data)
    .catch((err) => err);

export const confirmPayment = async (data) =>
  await axiosVerifiedMerchantAuthInstance
    .post('/payments/submit-payment', data)
    .then((res) => res.data);
