import { ACCESS_TOKEN, USER } from '../constant/misc';

export function saveAuthInfo(accessToken, userInfo) {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
  window.localStorage.setItem(USER, JSON.stringify(userInfo));
}

export function loadAuthInfo() {
  const accessToken = window.localStorage.getItem(ACCESS_TOKEN);
  const user = window.localStorage.getItem(USER);
  return { accessToken, user: user ? JSON.parse(user) : {} };
}

export function clearAuthInfo() {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.localStorage.removeItem(USER);
}
