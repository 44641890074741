import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

export default function TextInputControl(props) {
  const { control, InputProps, ...textFieldProps } = props;

  return (
    <Controller
      control={control}
      name={textFieldProps.id}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...textFieldProps}
          helperText={fieldState.error?.message ?? textFieldProps.helperText}
          InputProps={{
            ...InputProps,
            sx: {
              ...InputProps?.sx,
            },
          }}
        />
      )}
    />
  );
}
