import { createBrowserRouter } from 'react-router-dom';
import EmailVerification from '../module/auth/pages/EmailVerification';
import ForgotPassword from '../module/auth/pages/ForgotPassword';
import Login from '../module/auth/pages/Login';
import SetPassword from '../module/auth/pages/SetPassword';
import SignUp from '../module/auth/pages/SignUp';
import MerchantLogin from '../module/auth/pages/merchant/MerchantLogin';
import MerchantSignUp from '../module/auth/pages/merchant/MerchantSignUp';
import AutoMotivePlan from '../module/auto-motive/pages/AutoMotivePlan';
import Dashboard from '../module/dashboard/pages/Dashboard';
import HomePage from '../module/dashboard/pages/Home';
import RestaurantAdvertisement from '../module/merchant/pages/RestaurantAdvertisement';
import AutomotiveAdvertisement from '../module/merchant/pages/AutomotiveAdvertisement';
import CuisinePlan from '../module/restaurant/pages/CuisinePlan';
import Wallet from '../module/user/pages/Wallet';
import { CONSUMER, PROVIDER } from '../utils/constant/misc';
import ROUTES from '../utils/constant/routePaths';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';

const appRouter = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <HomePage />,
  },
  {
    path: ROUTES.HOME,
    element: <PublicRoute />,
    children: [
      {
        element: <Login />,
        path: ROUTES.USER.LOGIN,
      },
      {
        element: <SignUp />,
        path: ROUTES.USER.SIGN_UP,
      },
      {
        element: <ForgotPassword />,
        path: ROUTES.USER.FORGOT_PASSWORD,
      },
      {
        element: <SetPassword />,
        path: ROUTES.USER.SET_PASSWORD,
      },
      {
        element: <EmailVerification />,
        path: ROUTES.USER.VERIFY_EMAIL,
      },
      {
        element: <MerchantSignUp />,
        path: ROUTES.MERCHANT.SIGN_UP,
      },
      {
        element: <MerchantLogin />,
        path: ROUTES.MERCHANT.LOGIN,
      },
    ],
  },

  // ============= USER ROUTES ================= //
  {
    path: ROUTES.HOME,
    element: <ProtectedRoute role={CONSUMER} />,
    children: [
      {
        element: <Dashboard />,
        path: ROUTES.USER.DASHBOARD,
      },
      {
        element: <CuisinePlan />,
        path: ROUTES.USER.RESTAURANTS.CUISINE_PLAN,
      },
      {
        element: <AutoMotivePlan />,
        path: ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN,
      },
      {
        element: <Wallet />,
        path: ROUTES.USER.WALLET,
      },
    ],
  },

  // ============= MERCHANT ROUTES ================= //
  {
    path: ROUTES.HOME,
    element: <ProtectedRoute role={PROVIDER} />,
    children: [
      {
        element: <RestaurantAdvertisement />,
        path: ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT,
      },
      {
        element: <AutomotiveAdvertisement />,
        path: ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT,
      },
    ],
  },
]);

export default appRouter;
