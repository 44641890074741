import { RouterProvider } from 'react-router-dom';
import './App.css';
import './assets/styles/main.css';
import appRouter from './routes/Router';
import SnackbarProvider from './providers/SnackbarProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  return (
    <div className="App-wrapper">
      <SnackbarProvider>
        <RouterProvider router={appRouter} />
      </SnackbarProvider>
    </div>
  );
}

export default App;
