import MuiAutoComplete from '@mui/material/Autocomplete';
import React from 'react';
import { Controller } from 'react-hook-form';
import TextField from '../../textField';

function AutoComplete(props) {
  const { id, control, disabled, label, options } = props;
  return (
    <Controller
      control={control}
      name={id}
      rules={{
        required: { value: true, message: `${label} is required` },
      }}
      render={({ field, fieldState }) => (
        <MuiAutoComplete
          {...field}
          disabled={disabled}
          options={options}
          getOptionLabel={(option) => option?.label || ''}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={!!fieldState.error}
              helperText={fieldState.error?.message}
            />
          )}
          onChange={(_, data) => {
            field.onChange(data);
          }}
        />
      )}
    />
  );
}

export default AutoComplete;
