import {
  axiosVerifiedMerchantAuthInstance,
  axiosVerifiedAuthInstance,
} from '../../../utils/helper/axiosInstances';

export const createRestaurantAdvertisement = async (providerId, data) =>
  await axiosVerifiedMerchantAuthInstance
    .post(`/restaurants/${providerId}/advertisements`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const getRestaurantAdvertisements = async (providerId, type) =>
  await axiosVerifiedAuthInstance
    .get(`/restaurants/${providerId}/advertisements/${type}`)
    .then((res) => res.data)
    .catch((err) => err);

export const createAutomotiveAdvertisement = async (providerId, data) =>
  await axiosVerifiedMerchantAuthInstance
    .post(`/automotive/${providerId}/advertisements`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const getAutomotiveAdvertisements = async (providerId, type) =>
  await axiosVerifiedAuthInstance
    .get(`/automotive/${providerId}/advertisements/${type}`)
    .then((res) => res.data)
    .catch((err) => err);
