/* eslint-disable */
import axios from 'axios';
import ROUTES from '../constant/routePaths';
import { clearAuthInfo, loadAuthInfo } from './authTokenStorage';

const redirectToLogin = (isMerchant = false) => {
  clearAuthInfo();
  // window.location.replace(
  //   isMerchant ? ROUTES.MERCHANT.LOGIN : ROUTES.USER.LOGIN
  // );

  window.location.replace(ROUTES.HOME);
};

export const axiosAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const axiosVerifiedAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

export const axiosVerifiedMerchantAuthInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_MERCHANT_URL,
  headers: {
    'Content-type': 'application/json',
  },
});

axiosVerifiedAuthInstance.interceptors.request.use(
  function (config) {
    const { accessToken } = loadAuthInfo();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosVerifiedMerchantAuthInstance.interceptors.request.use(
  function (config) {
    const { accessToken } = loadAuthInfo();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosVerifiedAuthInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const originalRequest = error.config;
    const { data, status } = error.response;
    if (status === 401) {
      redirectToLogin();
    } else {
      return Promise.reject(data);
    }
  }
);

axiosVerifiedMerchantAuthInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // const originalRequest = error.config;
    const { data, status } = error.response;
    if (status === 401) {
      redirectToLogin(true);
    } else {
      return Promise.reject(data);
    }
  }
);
