import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import Button from '../../../components/core/button';
import CloseIcon from '@mui/icons-material/Close';

function AdvertisementPopup({ handleClose, showPopup }) {
  return (
    <Dialog open={showPopup} onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={10}>
            <Typography variant="h6" color="#4F8D7D">
              How it works
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <iframe
          id="savyomo-video-id"
          title="Savyomo"
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/WJqTJZItt1M?autoplay=1&mute=1`}
          frameBorder="0"
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  );
}

export default AdvertisementPopup;
