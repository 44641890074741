const ROUTES = {
  HOME: '/',
  USER: {
    LOGIN: '/login',
    SIGN_UP: '/signup',
    FORGOT_PASSWORD: '/forgot-password',
    SET_PASSWORD: '/set-password/:token',
    VERIFY_EMAIL: '/verify-email',
    VERIFY_PHONE: '/verify-phone',
    DASHBOARD: '/dashboard',
    WALLET: '/wallet',
    ACCOUNT: '/profile',
    RESTAURANTS: {
      CUISINE_PLAN: '/restaurants/cuisine-plans',
    },
    AUTO_MOTIVE: {
      AUTO_SERVICE_PLAN: '/automotive/auto-service-plans',
    },
  },
  MERCHANT: {
    LOGIN: '/merchant/login',
    SIGN_UP: '/merchant/signup',
    FORGOT_PASSWORD: '/merchant/forgot-password',
    VERIFY_EMAIL: '/merchant/verify-email',
    RESTAURANT_ADVERTISEMENT: '/merchant/restaurants/advertisement',
    AUTO_MOTIVE_ADVERTISEMENT: '/merchant/automotive/advertisement',
  },
};

export default ROUTES;
