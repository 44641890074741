// Input.js

import React from 'react';
import TextFields from '@mui/material/TextField';

function TextField({ label, value, onChange, ...restProps }) {
  return (
    <TextFields
      label={label}
      value={value}
      onChange={onChange}
      fullWidth
      margin="none"
      {...restProps}
    />
  );
}

export default TextField;
