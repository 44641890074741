import { yupResolver } from '@hookform/resolvers/yup';
import SaveIcon from '@mui/icons-material/Save';
import { DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { enqueueSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import TextInputControl from '../../../components/core/input/TextInputControl';
import { attachPaymentMethod } from '../services/paymentApi';

import '../../../assets/styles/payment.css';
import LoadingButton from '../../../components/core/button/LoadingButton';

function CardForm({ handleClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const userInfo = useSelector((state) => state.auth.userData);

  const [saveCardLoading, setSaveCardLoading] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        cardholderName: yup.string().required('Card holder name is required'),
        billingAddress: yup.string().required('Billing address is required'),
        city: yup.string().required('City is required'),
        state: yup.string().required('State is required'),
      }),
    []
  );

  const {
    control,
    formState: { errors },
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      cardholderName: '',
      billingAddress: '',
      city: '',
      state: '',
    },
  });

  const handleSaveCard = (e) => {
    setSaveCardLoading(true);
    e.preventDefault();
    elements.submit();

    const { billingAddress, cardholderName, city, state } = getValues();

    const requestData = {
      billing_details: {
        name: cardholderName,
        address: {
          city,
          state,
          line1: billingAddress,
        },
        email: userInfo.email,
        phone: userInfo.phone,
      },
    };

    stripe
      .createPaymentMethod({
        elements,
        params: { ...requestData },
      })
      .then((res) => {
        const { paymentMethod } = res;
        const {
          id,
          card: { brand, last4, exp_year, exp_month } = {},
          billing_details: { name: cardHolderName } = {},
        } = paymentMethod;

        attachPaymentMethod({ paymentMethodId: paymentMethod.id })
          .then((response) => {
            setSaveCardLoading(false);
            enqueueSnackbar(response.message, {
              variant: 'success',
            });
            handleClose(e, {
              id,
              holderName: cardHolderName,
              brand: brand,
              cardNumber: last4,
              expMonth: exp_month,
              expYear: exp_year,
            });
          })
          .catch((err) => {
            setSaveCardLoading(false);
            console.log(
              '🚀 ~ handleSaveCard ~ attachPaymentMethod   ~ err:',
              err
            );
          });
      })
      .catch((err) => {
        setSaveCardLoading(false);
        console.log('🚀 ~ handleSaveCard  ~ createPaymentMethod ~ err:', err);
      });
  };

  return (
    <>
      <DialogContent className="card-form">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography
              marginBottom={'4px'}
              color={'#30313d'}
              fontSize={'15px'}
            >
              Card Holder Name
            </Typography>
            <TextInputControl
              control={control}
              error={Boolean(errors.cardholderName)}
              id="cardholderName"
              rules={{ required: true }}
              fullWidth
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-input': { padding: '10px 14px' },
                },
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <PaymentElement id="payment-element" />
            {/* <CardElement /> */}
          </Grid>

          <Grid item xs={12}>
            <Typography
              marginBottom={'4px'}
              color={'#30313d'}
              fontSize={'15px'}
            >
              Billing Address
            </Typography>
            <TextInputControl
              control={control}
              error={Boolean(errors.billingAddress)}
              id="billingAddress"
              rules={{ required: true }}
              fullWidth
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-input': { padding: '10px 14px' },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              marginBottom={'4px'}
              color={'#30313d'}
              fontSize={'15px'}
            >
              City
            </Typography>
            <TextInputControl
              control={control}
              error={Boolean(errors.city)}
              id="city"
              rules={{ required: true }}
              fullWidth
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-input': { padding: '10px 14px' },
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              marginBottom={'4px'}
              color={'#30313d'}
              fontSize={'15px'}
            >
              State
            </Typography>
            <TextInputControl
              control={control}
              error={Boolean(errors.state)}
              id="state"
              rules={{ required: true }}
              fullWidth
              InputProps={{
                sx: {
                  '& .MuiOutlinedInput-input': { padding: '10px 14px' },
                },
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ margin: 1 }}>
        {/* <Button onClick={handleSaveCard}>Save card</Button> */}
        <LoadingButton
          loading={saveCardLoading}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          size="small"
          onClick={handleSaveCard}
        >
          Save card
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default CardForm;
