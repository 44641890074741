import { SnackbarProvider as Provider } from 'notistack';
import React, { useRef } from 'react';
// import { IconButton } from '@mui/material';
// import CancelIcon from '@mui/icons-material/Cancel';

export default function SnackbarProvider({ children }) {
  const notistackRef = useRef();

  return (
    <Provider
      ref={notistackRef}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      maxSnack={2}
      // action={(snackbarKey) => (
      //   <IconButton
      //     onClick={() => notistackRef.current.closeSnackbar(snackbarKey)}
      //   >
      //     <CancelIcon sx={{ fill: 'white' }} />
      //   </IconButton>
      // )}
    >
      {children}
    </Provider>
  );
}
