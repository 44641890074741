import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { getAuthInfo } from '../module/auth/services/authAPI';
import { CONSUMER } from '../utils/constant/misc';
import ROUTES from '../utils/constant/routePaths';

export default function ProtectedRoute({ role }) {
  const { isAuthenticated, userRole, userId } = getAuthInfo();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const advertisementId = searchParams.get('id');
  const consumerId = searchParams.get('consumer');
  const type = searchParams.get('type');

  useEffect(() => {
    if (advertisementId && consumerId && type) {
      searchParams.delete('id');
      searchParams.delete('consumer');
      searchParams.delete('type');
      if (!userRole || (userRole === CONSUMER && userId === consumerId)) {
        localStorage.setItem('advertisementId', advertisementId);
        localStorage.setItem('consumerId', consumerId);
        localStorage.setItem('type', type);
        navigate(userRole ? location.pathname : ROUTES.USER.LOGIN);
      } else if (userRole === CONSUMER && userId !== consumerId) {
        navigate(ROUTES.USER.DASHBOARD);
      }
    }
  }, [advertisementId, consumerId, type]);

  return isAuthenticated && role === userRole ? (
    <Outlet />
  ) : (
    <Navigate replace to={ROUTES.HOME} />
  );
}
