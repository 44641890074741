import MuiLoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import * as React from 'react';

export default function LoadingButton({
  loading,
  children,
  variant,
  color,
  onClick,
  type,
  ...restProps
}) {
  return (
    <Stack direction="row" spacing={2}>
      <MuiLoadingButton
        loading={loading}
        // loadingPosition="start"
        // startIcon={<SaveIcon />}
        sx={{ fontSize: 18 }}
        variant={variant ?? 'contained'}
        color={color}
        onClick={onClick}
        type={type}
        {...restProps}
      >
        {children}
      </MuiLoadingButton>
    </Stack>
  );
}
