import { axiosVerifiedAuthInstance } from '../../../utils/helper/axiosInstances';

export const getRestaurantsCuisines = async () =>
  await axiosVerifiedAuthInstance
    .get('/restaurants/cuisines')
    .then((res) => res.data)
    .catch((err) => err);

export const createRestaurantServiceRequest = async (userId, data) =>
  await axiosVerifiedAuthInstance
    .post(`/consumers/${userId}/restaurants/service-requests`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchRestaurantServiceRequest = async (userId) =>
  await axiosVerifiedAuthInstance
    .get(`/consumers/${userId}/restaurants/service-requests`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteRestaurantServiceRequest = async (
  userId,
  serviceRequestId
) =>
  await axiosVerifiedAuthInstance
    .delete(
      `/consumers/${userId}/restaurants/service-requests/${serviceRequestId}`
    )
    .then((res) => res.data)
    .catch((err) => err);
