// FormControl.js

import React from 'react';
import FormControls from '@mui/material/FormControl';

function FormControl({ noSpace, width, children, ...restProps }) {
  return (
    <FormControls
      className={noSpace ? '' : 'mb-2'}
      fullWidth={width ? '' : true}
      {...restProps}
    >
      {children}
    </FormControls>
  );
}

export default FormControl;
