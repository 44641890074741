import { createTheme } from '@mui/material';

export default createTheme({
  palette: {
    primary: {
      main: '#98C2B9',
    },
    secondary: {
      main: '#464646',
    },
    light: {
      main: '#e0e0e0',
    },
    white: {
      main: '#fff',
    },
    lightGreen: {
      main: '#98C2B9',
    },
    dark: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  MuiButton: {
    styleOverrides: {
      fontSize: '1rem',
      root: {
        fontSize: '1rem',
      },
    },
  },
});
