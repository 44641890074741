import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useState } from 'react';
import { Controller } from 'react-hook-form';

export function PasswordControl(props) {
  const { control, ...textFieldProps } = props;
  const [isRevealed, setRevealed] = useState(false);

  return (
    <Controller
      name={textFieldProps.id}
      control={control}
      render={({ field, fieldState }) => (
        <TextField
          {...field}
          {...textFieldProps}
          helperText={fieldState.error?.message ?? textFieldProps.helperText}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setRevealed(!isRevealed)}
                  sx={{ padding: 0 }}
                >
                  {isRevealed ? (
                    <VisibilityOff color="primary" />
                  ) : (
                    <Visibility color="primary" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          type={isRevealed ? 'text' : 'password'}
        />
      )}
    />
  );
}
