import { Box } from '@mui/material';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import React, { useEffect, useRef, useState } from 'react';
import OwlCarousel from 'react-owl-carousel';
import Col from '../../../components/col';
import Container from '../../../components/container';
import Footer from '../../../components/footer';
import Header from '../../../components/header';
import Row from '../../../components/row';
import Slides from '../../../components/slide';
import Typography from '../../../components/typography';
import ROUTES from '../../../utils/constant/routePaths';

import AutomotiveBanner from '../../../assets/images/automotive-services-banner.png';

import Burger from '../../../assets/images/burger.jpg';
import Chinese from '../../../assets/images/chinese.jpg';
import Indian from '../../../assets/images/indian.jpg';
import Italian from '../../../assets/images/italian.jpg';
import Mexican from '../../../assets/images/mexican.jpg';
import Other from '../../../assets/images/other.jpg';
import Pizza from '../../../assets/images/pizza.jpg';

import Batteries from '../../../assets/images/batteries.jpg';
import Brakes from '../../../assets/images/brakes.jpg';
import EngineRepair from '../../../assets/images/engine-repair.jpg';
import OilChange from '../../../assets/images/oil-change.jpg';
import Others from '../../../assets/images/others.jpg';
import TireRepair from '../../../assets/images/tire-repair.jpg';
import WheelAlignment from '../../../assets/images/wheel-alignment.jpg';

import HotelMotelBanner from '../../../assets/images/hotel-motel-banner.png';
import RestaurantBanner from '../../../assets/images/restaurant-banner.png';

import { useSelector } from 'react-redux';
import OfferInfoPopup from '../components/OfferInfoPopup';
import {
  getAutomotiveAdvertisementsByZipCodes,
  getRestaurantAdvertisementsByZipCodes,
} from '../services/dashboardApi';

const restaurantImages = {
  burgers: Burger,
  italian: Italian,
  pizzas: Pizza,
  mexican: Mexican,
  chinese: Chinese,
  indian: Indian,
  others: Other,
};

const automotiveImages = {
  brakes: Brakes,
  batteries: Batteries,
  alignment: WheelAlignment,
  others: Others,
  'tire-repair': TireRepair,
  'oil-change': OilChange,
  'engine-repair': EngineRepair,
};

function Dashboard() {
  const userInfo = useSelector((state) => state.auth.userData);

  const [restaurantOffers, setRestaurantOffers] = useState([]);
  const [autoMotiveOffers, setAutoMotiveOffers] = useState([]);

  const resContainerRef = useRef(null);
  const automotiveContainerRef = useRef(null);

  useEffect(() => {
    if (resContainerRef.current && restaurantOffers.length > 0) {
      const numRows = Math.ceil(restaurantOffers.length / 4);
      const rowHeight = numRows > 2 ? 200 : 350;
      const minHeight = numRows * rowHeight;
      resContainerRef.current.style.height = minHeight + 'px';
    }

    if (automotiveContainerRef.current && autoMotiveOffers.length > 0) {
      const numRows = Math.ceil(autoMotiveOffers.length / 4);
      const rowHeight = numRows > 2 ? 200 : 350;
      const minHeight = numRows * rowHeight;
      automotiveContainerRef.current.style.height = minHeight + 'px';
    }
  }, [restaurantOffers, autoMotiveOffers]);

  const getPopularRestaurantOffers = (zipCodes) => {
    getRestaurantAdvertisementsByZipCodes('MULTIPLE_DAY', { zipCodes }).then(
      (res) => {
        const advertisements = res?.map(({ cuisine, provider, name }) => ({
          offerName: name,
          merchantName: provider.name,
          cuisineKey: cuisine.key,
          address: provider.address,
          city: provider.city,
          zipCode: provider.zipCode,
        }));

        setRestaurantOffers([...advertisements]);
      }
    );
  };

  const getPopularAutomotiveOffers = (zipCodes) => {
    getAutomotiveAdvertisementsByZipCodes('MULTIPLE_DAY', { zipCodes }).then(
      (res) => {
        const advertisements = res?.map(({ service, provider, name }) => ({
          offerName: name,
          merchantName: provider.name,
          serviceKey: service.key,
          address: provider.address,
          city: provider.city,
          zipCode: provider.zipCode,
        }));
        setAutoMotiveOffers([...advertisements]);
      }
    );
  };

  useEffect(() => {
    const { zipCode } = userInfo;
    const zipCodes = [
      `${+zipCode - 2}`,
      `${+zipCode - 1}`,
      zipCode,
      `${+zipCode + 1}`,
      `${+zipCode + 2}`,
    ];
    getPopularRestaurantOffers(zipCodes);
    getPopularAutomotiveOffers(zipCodes);
  }, []);

  return (
    <Container>
      <Header />
      <OfferInfoPopup />
      <Box marginTop="65px">
        <OwlCarousel
          className="owl-theme"
          autoplay
          items={1}
          loop
          autoplayTimeout={11000}
        >
          <Slides
            key={'restaurants'}
            src={RestaurantBanner}
            title="Discover ways to save money while indulging in delicious meals"
            btn="Create cuisine Plans"
            navKey={ROUTES.USER.RESTAURANTS.CUISINE_PLAN}
          />
          <Slides
            key={'automotive-service'}
            src={AutomotiveBanner}
            title="Take advantage of our flexible automotive service offerings today!"
            btn="Create AUtomotive Plans"
            navKey={ROUTES.USER.AUTO_MOTIVE.AUTO_SERVICE_PLAN}
          />
          <Slides
            key={'hotels-motels'}
            src={HotelMotelBanner}
            title="Experience savings by booking Hotels & Motels"
            btn="Create Accommodation Plans"
            btnDisabled
          />
        </OwlCarousel>
      </Box>
      <Box className="m-4">
        <Typography
          variant="h3"
          fontWeight="bold"
          textAlign="center"
          marginBottom={3}
          sx={{ color: '#4d9b8a' }}
        >
          Popular
        </Typography>

        <Typography
          variant="h4"
          fontWeight="bold"
          marginInlineStart={3}
          marginBottom={5}
          sx={{ color: '#4d9b8a' }}
        >
          Restaurant Offers
        </Typography>

        {restaurantOffers?.length > 0 ? (
          <Box ref={resContainerRef} overflow="auto">
            <Row>
              {restaurantOffers.map(
                (
                  {
                    offerName,
                    merchantName,
                    cuisineKey,
                    address,
                    city,
                    zipCode,
                  },
                  index
                ) => (
                  <Col key={index + 1} sm={3}>
                    <Box textAlign="center">
                      <Box className="popular" margin="auto" display="table">
                        <img
                          src={restaurantImages[cuisineKey]}
                          alt="banner-img"
                          width={160}
                          className="img-fluid"
                          style={{ borderRadius: '8px', height: '110px' }}
                        />
                      </Box>
                      <Typography
                        sx={{ color: '#4d9b8a' }}
                        variant="h5"
                        marginTop={1}
                      >
                        {merchantName}
                      </Typography>
                      <Typography variant="h6">{offerName}</Typography>
                      <Typography variant="subtitle1" sx={{ color: '#4d9b8a' }}>
                        {address}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        marginBottom={3}
                        sx={{ color: '#4d9b8a' }}
                      >
                        {city}, {zipCode}
                      </Typography>
                    </Box>
                  </Col>
                )
              )}
            </Row>
          </Box>
        ) : (
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            There are no offers at the moment, but they will be available soon.
            😊
          </Typography>
        )}

        <Typography
          variant="h4"
          fontWeight="bold"
          marginInlineStart={3}
          marginTop={5}
          marginBottom={5}
          sx={{ color: '#4d9b8a' }}
        >
          Automotive Offers
        </Typography>

        {autoMotiveOffers?.length > 0 ? (
          <Box ref={automotiveContainerRef} overflow="auto">
            <Row>
              {autoMotiveOffers.map(
                (
                  {
                    offerName,
                    merchantName,
                    serviceKey,
                    address,
                    city,
                    zipCode,
                  },
                  index
                ) => (
                  <Col key={index + 1} sm={3}>
                    <Box textAlign="center">
                      <Box className="popular" margin="auto" display="table">
                        <img
                          src={automotiveImages[serviceKey]}
                          alt="banner-img"
                          width={160}
                          className="img-fluid"
                          style={{ borderRadius: '8px', height: '110px' }}
                        />
                      </Box>
                      <Typography
                        sx={{ color: '#4d9b8a' }}
                        variant="h5"
                        marginTop={1}
                      >
                        {merchantName}
                      </Typography>
                      <Typography variant="h6">{offerName}</Typography>
                      <Typography variant="subtitle1" sx={{ color: '#4d9b8a' }}>
                        {address}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        marginBottom={3}
                        sx={{ color: '#4d9b8a' }}
                      >
                        {city}, {zipCode}
                      </Typography>
                    </Box>
                  </Col>
                )
              )}
            </Row>
          </Box>
        ) : (
          <Typography variant="h6" fontWeight="bold" textAlign="center">
            There are no offers at the moment, but they will be available soon.
            😊
          </Typography>
        )}
      </Box>

      <Footer />
    </Container>
  );
}

export default Dashboard;
