import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import { PasswordControl } from '../../../components/core/input/PasswordControl';
import TextInputControl from '../../../components/core/input/TextInputControl';
import { debounceFunc } from '../../../utils/helper/utility';
import { validateZipCode } from '../services/authAPI';
import { Link } from 'react-router-dom';
import TermsConditions from './TermsConditions';
import PrivacyPolicy from './PrivacyPolicy';

function SignUpForm({ onSignUp }) {
  const [isValidatingZipCode, setIsValidatingZipCode] = useState(false);
  const [showTermsPopup, setShowTermsPopup] = useState(false);
  const [showPrivacyPopup, setShowPrivacyPopup] = useState(false);

  const formSchema = useMemo(
    () =>
      yup.object({
        firstName: yup.string().required('First name is required'),
        lastName: yup.string().required('Last name is required'),
        email: yup
          .string()
          .email('Invalid email address')
          .required('Email address is required'),
        confirmEmail: yup
          .string()
          .required('Confirm Email address is required')
          .test(
            'emails-match',
            'Email and Confirm email must match',
            function (value) {
              return this.parent.email === value;
            }
          ),
        phone: yup
          .string()
          .matches(
            /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})(?:[-.\s]?)\d{3}(?:[-.\s]?)\d{4}$/,
            'Phone number is not valid'
          ),
        password: yup
          .string()
          .required('Please choose a password')
          // .min(6, 'Password must be at least 6 characters long.'),
          .matches(
            /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{8,}$/,
            'Your password must be at least 8 characters long and include at least one number, one uppercase letter, one lowercase letter, and one special character.'
          ),
        confirmPassword: yup
          .string()
          .required('Please confirm your password')
          .test('passwords-match', 'Passwords must match', function (value) {
            return this.parent.password === value;
          }),
        zipCode: yup
          .number()
          .typeError('Valid zip code is required!')
          .required('Valid zip code is required!'),
        city: yup.string().required('City is required'),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
    resetField,
    setValue,
    setError,
    clearErrors,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      phone: '',
      password: '',
      confirmPassword: '',
      role: 'consumer',
      zipCode: '',
      city: '',
      state: '',
      country: 'US',
      timezone: '',
    },
  });

  const zipCode = watch('zipCode');

  const handleChange = (value) => {
    if (value) {
      validateZipCode('US', value).then((res) => {
        setIsValidatingZipCode(false);
        const { city, state, timzones, statusCode, message } = res;
        if (statusCode === 400) {
          setError('zipCode', { message });
          resetField('city');
          return;
        }
        clearErrors('zipCode');
        setValue('city', city);
        setValue('timezone', timzones[0]);
        setValue('state', state);
      });
    }
  };

  const optimizedFn = useCallback(debounceFunc(handleChange), []);

  useEffect(() => {
    setIsValidatingZipCode(true);
    optimizedFn(zipCode);
  }, [zipCode]);

  const handleSignUp = (data, event) => {
    event.preventDefault();
    const [firstName, lastName] = getValues(['firstName', 'lastName']);
    onSignUp({ ...data, name: `${firstName} ${lastName}` });
  };

  return (
    <>
      <TermsConditions
        showTermsPopup={showTermsPopup}
        handleClose={() => {
          setShowTermsPopup(false);
        }}
      />
      <PrivacyPolicy
        showPrivacyPolicyPopup={showPrivacyPopup}
        handleClose={() => {
          setShowPrivacyPopup(false);
        }}
      />
      <Box component="form" onSubmit={handleSubmit(handleSignUp)}>
        <Grid container spacing={2} className="mb-2">
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              error={Boolean(errors.firstName)}
              id="firstName"
              label="First Name"
              rules={{ required: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              error={Boolean(errors.lastName)}
              id="lastName"
              label="Last Name"
              rules={{ required: true }}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              error={Boolean(errors.email)}
              id="email"
              label="Email"
              rules={{ required: true }}
              type="text"
              fullWidth
              InputProps={{
                'aria-label': 'email-input',
                endAdornment: (
                  <InputAdornment position="end">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              error={Boolean(errors.confirmEmail)}
              id="confirmEmail"
              label="Confirm Email"
              rules={{ required: true }}
              type="text"
              fullWidth
              InputProps={{
                'aria-label': 'confirm-email-input',
                endAdornment: (
                  <InputAdornment position="end">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInputControl
              control={control}
              error={Boolean(errors.phone)}
              id="phone"
              label="Phone"
              rules={{ required: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PasswordControl
              control={control}
              error={Boolean(errors.password)}
              id="password"
              label="Password"
              rules={{ required: true }}
              type="password"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <PasswordControl
              control={control}
              error={Boolean(errors.confirmPassword)}
              id="confirmPassword"
              label="Confirm Password"
              rules={{ required: true }}
              type="password"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              error={Boolean(errors.zipCode)}
              id="zipCode"
              label="Zip Code"
              rules={{ required: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              control={control}
              id="city"
              label="City"
              rules={{ required: true }}
              fullWidth
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={12} textAlign={'left'}>
            <Typography>
              By clicking Sign Up, you agree to our{' '}
              <Link
                onClick={() => {
                  setShowTermsPopup(true);
                }}
              >
                Terms
              </Link>{' '}
              and
              <Link
                onClick={() => {
                  setShowPrivacyPopup(true);
                }}
              >
                {' '}
                Privacy Policy
              </Link>
              .
            </Typography>
          </Grid>
        </Grid>
        <Button
          size="large"
          fullWidth
          type="submit"
          disabled={
            isValidatingZipCode || Object.keys(errors).length || !isDirty
          }
        >
          Sign up
        </Button>
      </Box>
    </>
  );
}

export default SignUpForm;
