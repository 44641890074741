import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import isEmpty from 'lodash/isEmpty';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import AutoComplete from '../../../components/core/autocomplete/AutoComplete';
import LoadingButton from '../../../components/core/button/LoadingButton';
import TextInputControl from '../../../components/core/input/TextInputControl';
import CheckoutForm from '../../payment/components/CheckoutForm';
import {
  createPaymentIntent,
  deletePaymentMethod,
  getAttachedCards,
} from '../../payment/services/paymentApi';
import { purchasePromoCodes } from '../services/dashboardApi';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function PurchaseCode({
  type,
  promoCodeInfo,
  handleSuccess,
  updateAvailableCodeCount,
}) {
  const {
    merchantName,
    offerName,
    originalPrice,
    discountedPrice,
    availableCodeCount,
    advertisementId,
  } = promoCodeInfo || {};

  const userInfo = useSelector((state) => state.auth.userData);

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [quantityOptions, setQuantityOptions] = useState([]);
  const [showCardPopup, setShowCardPopup] = useState(false);
  const [isStripeLoaded, setIsStripeLoaded] = useState(false);
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [addCardLoading, setAddCardLoading] = useState(false);
  const [submitPaymentLoading, setSubmitPaymentLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);

  const formSchema = useMemo(
    () =>
      yup.object({
        quantity: yup
          .object()
          .shape({
            id: yup.string().required(),
            label: yup.string().required(),
          })
          .required('Quantity is required')
          .test('quantity-check', 'Quantity is required', function (value) {
            return !isEmpty(value);
          }),
        amountDue: yup
          .number()
          .min(1, 'Amount due price must be grater than 0.'),
      }),
    []
  );

  const {
    control,
    formState: { errors, isDirty, isValid },
    handleSubmit,
    watch,
    setValue,
    getValues,
    resetField,
    reset,
  } = useForm({
    resolver: yupResolver(formSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      offerName: offerName,
      merchantName: merchantName,
      quantity: {},
      amountDue: '',
    },
  });

  const quantity = watch('quantity');

  useEffect(() => {
    fetchCards();
  }, []);

  useEffect(() => {
    if (availableCodeCount) {
      const countArr = [];
      for (let i = 1; i <= availableCodeCount; i++) {
        countArr.push({ id: i, label: i });
      }
      setQuantityOptions(countArr);
    }
  }, [availableCodeCount]);

  useEffect(() => {
    if (isEmpty(quantity)) {
      setValue('amountDue', '');
    } else {
      setValue('amountDue', quantity.id);
    }
  }, [quantity]);

  const addNewCard = () => {
    setAddCardLoading(true);
    const amountDue = getValues('amountDue');

    stripePromise
      .then(() => {
        const amount = amountDue * 100;
        if (paymentIntent?.amount && amount === paymentIntent.amount) {
          setAddCardLoading(false);
          setShowCardPopup(true);
        } else {
          createPaymentIntent({ amount, customerId: userInfo.customerId }).then(
            (res) => {
              if (res?.data) {
                setIsStripeLoaded(true);
                setPaymentIntent({ ...res.data });
                setShowCardPopup(true);
              } else {
                enqueueSnackbar(res?.message, { variant: 'error' });
              }
              setAddCardLoading(false);
            }
          );
        }
      })
      .catch((error) => {
        console.log('🚀 ~ addNewCard ~ error:', error);
      });
  };

  const fetchCards = () => {
    getAttachedCards(userInfo.customerId)
      .then((res) => {
        const { data } = res;
        if (data) setPaymentMethods([...data]);
      })
      .catch((err) => err);
  };

  const deleteCard = (paymentMethodId) => {
    deletePaymentMethod(paymentMethodId).then((res) => {
      if (res.status === 200) {
        enqueueSnackbar(res.message, {
          variant: 'success',
        });
        fetchCards();
      }
    });
  };

  const resetAllFields = () => {
    reset();
    setPaymentIntent(null);
    setQuantityOptions([]);
    setSelectedPaymentMethod('');
  };

  const purchasePromoCode = (data) => {
    setSubmitPaymentLoading(true);
    const requestData = {
      amount: data.amountDue * 100,
      paymentMethodId: selectedPaymentMethod,
      customerId: userInfo.customerId,
      id: paymentIntent?.id,
      quantity: +data.quantity.id,
    };
    purchasePromoCodes(advertisementId, type, requestData).then((res) => {
      setSubmitPaymentLoading(false);
      if (res.statusCode === 200) {
        resetAllFields();
        handleSuccess(res.data.promoCodesList);
        return;
      }

      if (res?.remainingCodes === 0) {
        updateAvailableCodeCount();
        return;
      }

      enqueueSnackbar(res?.message ?? 'Payment failed!', {
        variant: 'error',
      });

      if (res?.remainingCodes > 0) {
        const countArr = [];
        for (let i = 1; i <= res.remainingCodes; i++) {
          countArr.push({ id: i, label: i });
        }
        setSelectedPaymentMethod('');
        resetField('quantity');
        setQuantityOptions(countArr);
      }
    });
  };

  const onSubmit = () => {
    handleSubmit(purchasePromoCode)();
  };

  return (
    <>
      <CheckoutForm
        stripe={stripePromise}
        open={showCardPopup}
        isStripeLoaded={isStripeLoaded}
        clientSecret={paymentIntent?.clientSecret}
        handleClose={(event, paymentMethod) => {
          if (paymentMethod && typeof paymentMethod === 'object') {
            setPaymentMethods([...paymentMethods, paymentMethod]);
            setSelectedPaymentMethod(paymentMethod.id);
          }
          setShowCardPopup(false);
        }}
      />
      <DialogContent>
        <Grid container spacing={2} pt={'5px'}>
          <Grid item xs={12} sm={12} pb={1}>
            <Box
              display={'flex'}
              className="original-price"
              alignItems={'center'}
            >
              <h2 className="price-strikethrough">${originalPrice}</h2>
              <h2 className="ml-2">
                <span className="text-success">Now </span>${discountedPrice}
              </h2>
              <h3 className="py-4 price-discount">
                <span className="bg-red p-1 radius-05">
                  You Save ${originalPrice - discountedPrice}
                </span>
              </h3>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInputControl
              control={control}
              id="merchantName"
              label="Merchant Name"
              rules={{ required: true }}
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInputControl
              control={control}
              id="offerName"
              label="Offer Name"
              rules={{ required: true }}
              fullWidth
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoComplete
              id="quantity"
              control={control}
              label="Quantity"
              options={quantityOptions}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInputControl
              disabled
              control={control}
              id="amountDue"
              label="Amount Due"
              rules={{ required: true }}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} marginTop={2} className="testing">
          <Box
            border={1}
            padding={2}
            borderColor="rgba(0, 0, 0, 0.23)"
            borderRadius={1}
          >
            <FormControl fullWidth sx={{ mb: 1 }}>
              <FormLabel sx={{ mb: 1 }}>Choose a Payment Method</FormLabel>
              <RadioGroup
                aria-labelledby="payment-method"
                name="payment-methods-group"
              >
                {paymentMethods?.map(
                  ({
                    id,
                    brand,
                    holderName,
                    expMonth,
                    expYear,
                    cardNumber,
                  }) => (
                    <Grid container key={id}>
                      <Grid item xs={11}>
                        <FormControlLabel
                          value={id}
                          control={
                            <Radio
                              checked={selectedPaymentMethod === id}
                              onChange={(event) => {
                                setSelectedPaymentMethod(event.target.value);
                              }}
                            />
                          }
                          label={brand}
                          sx={{
                            textTransform: 'capitalize',
                            marginRight: 1,
                          }}
                        />
                        <Typography variant="p" marginRight={2}>
                          ending in {cardNumber}
                        </Typography>
                        <Typography variant="p" marginRight={1}>
                          {holderName}
                        </Typography>
                        <Typography variant="p">
                          {expMonth?.toString()?.padStart(2, 0)} / {expYear}
                        </Typography>
                      </Grid>
                      <Grid item xs={1}>
                        <IconButton
                          onClick={() => {
                            deleteCard(id);
                            setSelectedPaymentMethod('');
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )
                )}
              </RadioGroup>
            </FormControl>
            <LoadingButton
              loading={addCardLoading}
              loadingPosition="start"
              startIcon={<AddIcon />}
              size="small"
              disabled={Object.keys(errors).length || !isDirty || !isValid}
              onClick={addNewCard}
            >
              Add Card
            </LoadingButton>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ margin: 1 }}>
        <LoadingButton
          loading={submitPaymentLoading}
          size="small"
          disabled={
            Object.keys(errors).length || !isDirty || !selectedPaymentMethod
          }
          onClick={onSubmit}
        >
          Submit a Payment
        </LoadingButton>
      </DialogActions>
    </>
  );
}

export default PurchaseCode;
