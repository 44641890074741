import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import TextInputControl from '../../../components/core/input/TextInputControl';
import ROUTES from '../../../utils/constant/routePaths';
import { forgetPassword } from '../services/authAPI';
import { enqueueSnackbar } from 'notistack';

function ForgotPassword() {
  const navigate = useNavigate();

  const formSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email('Invalid email address')
          .required('Email address is required'),
      }),
    []
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
    },
  });

  const resetPassword = (data) => {
    forgetPassword(data.email).then((res) => {
      enqueueSnackbar(res.message, {
        variant: res.status === 200 ? 'success' : 'error',
      });
    });
  };

  return (
    <React.Fragment>
      <Box className="text-center mb-3">
        <Typography variant="h4">Forgot Password</Typography>
      </Box>
      <Box component="form" onSubmit={handleSubmit(resetPassword)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <TextInputControl
              id="email"
              label="Email"
              type="text"
              control={control}
              error={Boolean(errors.email)}
              rules={{ required: true }}
              fullWidth={true}
              InputProps={{
                'aria-label': 'email-input',
                endAdornment: (
                  <InputAdornment position="end">
                    <Email color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Button size="large" fullWidth type="submit">
              RESET PASSWORD
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              size="large"
              fullWidth
              color="light"
              onClick={() => {
                navigate(ROUTES.USER.LOGIN);
              }}
            >
              BACK TO LOGIN
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default ForgotPassword;
