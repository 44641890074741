/**
 * It returns a function that will call the original function after a delay of 500ms
 * @param func - The function that you want to debounce.
 * @returns A function that will call the passed in function after 500ms.
 */

export const debounceFunc = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 600);
  };
};
