export const ACCESS_TOKEN = 'savyomo_access_token';
export const REFRESH_TOKEN = 'savyomo_refresh_token';
export const USER = 'savyomo_user';

export const STATUS = Object.freeze({
  LOADING: 'loading',
  SUCCESS: 'success',
  ERROR: 'error',
});

export const CONSUMER = 'consumer';
export const PROVIDER = 'provider';

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
