import { yupResolver } from '@hookform/resolvers/yup';
import { Email } from '@mui/icons-material';
import { Box, Grid, InputAdornment, Link, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../components/core/button';
import { PasswordControl } from '../../../components/core/input/PasswordControl';
import TextInputControl from '../../../components/core/input/TextInputControl';
import ROUTES from '../../../utils/constant/routePaths';
import { doLogin } from '../services/authAPI';
import { updateUserData } from '../services/authSlice';

function LoginForm({ role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formSchema = useMemo(
    () =>
      yup.object({
        email: yup
          .string()
          .email('Invalid email address')
          .required('Email address is required'),
        password: yup
          .string()
          .required('Please choose a password')
          .min(6, 'Password must be at least 6 characters long.'),
      }),
    []
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSignIn = (data) => {
    data.role = role;
    doLogin(data)
      .then((res) => {
        const { user, statusCode, message } = res || {};
        if (user) {
          dispatch(updateUserData(res));
          if (user.role === 'provider') {
            const { businessLine: { key } = {} } = user;
            key === 'restaurants'
              ? navigate(ROUTES.MERCHANT.RESTAURANT_ADVERTISEMENT)
              : navigate(ROUTES.MERCHANT.AUTO_MOTIVE_ADVERTISEMENT);
          } else {
            navigate(ROUTES.USER.DASHBOARD);
          }
        } else {
          if (statusCode === 400) {
            navigate(ROUTES.USER.VERIFY_EMAIL, { state: getValues('email') });
          } else if (statusCode === 404) {
            setError('email', { message });
          } else {
            setError('password', { message });
          }
        }
      })
      .catch(() => {});
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSignIn)}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextInputControl
            id="email"
            label="Email"
            type="text"
            control={control}
            error={Boolean(errors.email)}
            rules={{ required: true }}
            fullWidth
            InputProps={{
              'aria-label': 'email-input',
              endAdornment: (
                <InputAdornment position="end">
                  <Email color="primary" />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <PasswordControl
            id="password"
            label="Password"
            type="password"
            control={control}
            error={Boolean(errors.password)}
            rules={{ required: true }}
            fullWidth
          />
        </Grid>

        <Grid item xs={6} sm={6}>
          {/* <FormControl>
            <FormControlLabel
              sx={{ margin: 0 }}
              label={<Typography>&nbsp;Remember Me</Typography>}
              control={<CheckBox color="primary" />}
            />
          </FormControl> */}
        </Grid>
        <Grid item xs={6} sm={6} textAlign={'end'}>
          <Link
            component={RouterLink}
            sx={{ textDecoration: 'none' }}
            to={ROUTES.USER.FORGOT_PASSWORD}
          >
            <Typography>Forgot Password?</Typography>
          </Link>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Button size="large" fullWidth type="submit">
            Sign In
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default LoginForm;
