import { Box, Container, Grid } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import SavyomoLogo from '../assets/images/savyomo-logo.svg';
import LoginSideImage from '../assets/images/side-image.png';
import ROUTES from '../utils/constant/routePaths';

function AuthLayout() {
  const navigate = useNavigate();

  return (
    <Container disableGutters maxWidth={'xl'}>
      <Grid container>
        <Grid item sm={6} md={6} lg={6}>
          <img
            src={LoginSideImage}
            alt="money-img"
            style={{ height: '100vh' }}
            width={'100%'}
          />
        </Grid>
        <Grid item sm={6} md={6} lg={6}>
          <Box className="item-center" sx={{ height: '100%' }}>
            <Box className="m-4">
              <Box
                className="m-auto"
                onClick={() => {
                  navigate(ROUTES.HOME);
                }}
                sx={{ width: '410px', cursor: 'pointer' }}
              >
                <img src={SavyomoLogo} alt="savyomo-logo" />
              </Box>
              <Outlet />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default AuthLayout;
