import CloseIcon from '@mui/icons-material/Close';
import {
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import React from 'react';
import Button from '../../../components/core/button';
import CardForm from './CardForm';

function CheckoutForm({
  open,
  handleClose,
  isStripeLoaded,
  clientSecret,
  stripe,
}) {
  const appearance = {
    variables: {
      colorPrimary: '#98C2B9',
      colorText: '#000',
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance,
    paymentMethodCreation: 'manual',
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        <Grid container>
          <Grid item xs={12} sm={8}>
            <Typography variant="h5">Add a credit or debit card</Typography>
          </Grid>
          <Grid item xs={12} sm={4} textAlign="right">
            <Button variant="text" onClick={handleClose}>
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>

      {isStripeLoaded && clientSecret ? (
        <Elements options={options} stripe={stripe}>
          <CardForm handleClose={handleClose} />
        </Elements>
      ) : (
        <Grid container textAlign={'center'}>
          <Grid item xs={12} padding={5}>
            <CircularProgress color="primary" />
          </Grid>
          <Grid item xs={12} padding={3} />
        </Grid>
      )}
    </Dialog>
  );
}

export default CheckoutForm;
