import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const environment = process.env.REACT_APP_ENVIRONMENT;

const store = configureStore({
  reducer: rootReducer,
  devTools: environment !== 'production',
});

export default store;
